<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import { mapState } from 'vuex'

import ChangePwd from '@/components/ChangePwd.vue'
import ThreeD from './threeD.vue'
import House from './house.vue'
import Warm from '@/components/Warm/dialog.vue'
export default {
  components: { ChangePwd, ThreeD, House, Warm },
  data() {
    return {
      activeRouter: 'ThreeD',
      routers: [
        {
          label: '首页',
          value: 'ThreeD',
        },
        {
          label: '实时',
          value: 'House',
        },
      ],
      timer: null,
    }
  },
  mounted() {
    this.$bus.$on('warm-show', () => this.$refs.warm.showDialog())
    this.timerFn()
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    timerFn() {
      if (this.timer) clearTimeout(this.timer)
      const time = Math.floor(Math.random() * 60000)
      this.timer = setTimeout(() => {
        this.handler()
        this.timerFn()
      }, time)
    },
    handler() {
      this.$bus.$emit('warm-show')
    },
    // 退出功能···
    logoutHandler() {
      this.$router.push({ path: '/logout' })
    },
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer)
  },
}
</script>

<template>
  <div class="home-page">
    <!-- 头部 -->
    <ChangePwd ref="change-pwd" />
    <div class="home-page_header">
      <nav class="home-page_nav">
        <ul>
          <li
            v-for="item in routers"
            :key="item.value"
            @click="activeRouter = item.value"
            :class="{ active: activeRouter === item.value }"
          >
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </nav>
      <div class="navbar-custom-menu">
        <ul style="float: right" class="nav navbar-nav">
          <li class="dropdown" v-if="userInfo">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <img
                style="width: 20px; height: 20px; margin-right: 8px"
                src="@/assets/images/users.svg"
                alt=""
              />
              {{ userInfo.NickName || userInfo.UserName }}</a
            >
            <ul class="dropdown-menu">
              <li>
                <a
                  @click.prevent="$refs['change-pwd'].dialogFormShow()"
                  role="button"
                  ><i class="fa fa-key"></i> 修改密码</a
                >
              </li>
              <li>
                <a @click.prevent="logoutHandler" role="button"
                  ><i class="fa fa-sign-out"></i> 退出登陆</a
                >
              </li>
            </ul>
          </li>
          <li v-else>
            <a href="/login.html"><i class="fa fa-user"></i> 登录</a>
          </li>
        </ul>
        <div @click="$router.go(-1)" class="home-page-back">
          <i class="el-icon-position"></i>
        </div>
      </div>
    </div>
    <div class="home-page_main">
      <component :is="activeRouter" />
    </div>
    <Warm ref="warm" />
  </div>
</template>

<style lang="less" scoped>
.home-page {
  width: 100vw;
  height: 100vh;
  background-color: #0e0f16;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vw;
    z-index: 1;
    background: radial-gradient(
      circle,
      rgba(99, 106, 176, 1) 0%,
      rgba(14, 15, 22, 0) 70%
    );
    border-radius: 50%;
    pointer-events: none; /* 确保伪元素不干扰页面交互 */
  }
  .home-page-back {
    color: #b9b8b8;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    cursor: pointer;
    .el-icon-position {
      color: #b9b8b8;
    }
    &:hover {
      background-color: #eee;
      .el-icon-position {
        color: var(--color-black);
      }
    }
  }
  .home-page_header {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .home-page_nav {
      text-align: center;
      height: 100%;
      ul {
        list-style-type: none; /* 去掉默认的列表样式 */
        padding: 0; /* 去掉内边距 */
        margin: 0; /* 去掉外边距 */
        display: inline-block; /* 使ul变成内联块元素 */
        height: 100%;
        > li {
          line-height: 50px;
          color: #9b9ca6;
          display: inline-block;
          padding: 0 30px;
        }
        > li.active {
          color: #fff;
          > span {
            position: relative;
            &::after {
              position: absolute;
              content: '';
              display: block;
              bottom: -8px;
              left: -10px;
              width: calc(100% + 20px);
              border-bottom: 2px solid #4d6ee7;
            }
          }
        }
      }
    }
    .navbar-custom-menu {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;

      .dropdown-toggle {
        color: #fff;
        display: flex;
        align-items: center;
        img {
          display: block;
        }
      }
      .dropdown-toggle:hover,
      .dropdown-toggle :focus {
        color: #0e0f16;
      }
      .nav .open > li:hover,
      .nav .open > a:focus {
        color: #0e0f16;
      }
      .dropdown.open {
        a {
          color: #0e0f16;
        }
      }
      .navbar-home {
        float: left;
        position: relative;
        display: block;
        padding: 0 15px;

        i {
          color: #333;
          line-height: 50px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .home-page_main {
    position: relative;
    z-index: 2;
    height: calc(100vh - 50px);
  }
}
</style>
