<script>
/**
 * @author lixidong
 * @description 基础配置
 */
import PlayConfiguration from './PlayConfiguration.vue'
import SignalingServices from './SignalingServices.vue'
import StreamingServices from './StreamingServices.vue'

export default {
  components: { PlayConfiguration, SignalingServices, StreamingServices },
  data() {
    return {
      activeName: 'first',
    }
  },
}
</script>

<template>
  <div class="basis-setting">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="播放配置" name="first">
        <PlayConfiguration />
      </el-tab-pane>
      <!-- <el-tab-pane label="信令服务配置" name="second">
          <signaling-services />
        </el-tab-pane>
        <el-tab-pane label="流媒体服务配置" name="third">
          <streaming-services />
        </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
