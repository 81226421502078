const resizeObserverMap = new WeakMap()

export default {
  bind(el, binding) {
    // 创建 ResizeObserver 实例
    const resizeObserver = new ResizeObserver((entries) => {
      // 执行传递的回调函数
      if (binding.value && typeof binding.value === 'function') {
        binding.value(entries)
      }
    })

    // 观察元素
    resizeObserver.observe(el)

    // 将 ResizeObserver 存储到 WeakMap 中
    resizeObserverMap.set(el, resizeObserver)
  },
  unbind(el) {
    // 从 WeakMap 中获取 ResizeObserver 实例
    const resizeObserver = resizeObserverMap.get(el)

    if (resizeObserver) {
      // 停止观察元素
      resizeObserver.unobserve(el)

      // 从 WeakMap 中删除
      resizeObserverMap.delete(el)
    }
  },
}
