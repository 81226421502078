<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import UserTe from './user.vue'
import GroupTe from './group.vue'
export default {
  name: 'user_mangement',
  components: { UserTe, GroupTe },
  data() {
    return {
      activeName: 'user',
    }
  },
}
</script>

<template>
  <div class="user-mangement">
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户管理" name="user">
        <user-te v-if="activeName == 'user'"></user-te>
      </el-tab-pane>
      <el-tab-pane label="组织架构" name="group">
        <group-te v-if="activeName == 'group'"></group-te>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style lang="less" scoped>
.user-mangement {
  /deep/ .el-tabs {
    height: 100%;

    .el-tabs__content {
      height: calc(100% - 55px);

      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
