<script>
/**
 * @auhtor lixidong
 * @description 视频时间刻度，定制。
 * @warning 1. 该组件规范传参，未做类型校验，使用时请注意。
 */
import { uuid as _uuid, debounce } from '@/utils/tool.js'

export default {
  props: {
    // 步长
    step: {
      type: Number,
      default: 5,
    },
    // 时间片段
    timeSlice: {
      type: Array,
      default: () => [{ start: '00:00', end: '24:00' }],
    },
  },
  data() {
    return {
      cursorText: '00:00',
      cursorPosition: 0,
      timeRuleWidth: 0,
      isDraggingc: false,
      isDragging: false,
      startDragX: 0,
      startScrollLeft: 0,
      timeChangeOld: 0,
    }
  },
  computed: {
    // timeSlice 数据格式化
    timeSliceFormat() {
      // 先判断是否是想要的格式，不是就返回空数组
      if (!this.timeSlice.length) return []
      var minStartMinute = 0
      const timeSlice = this.timeSlice.map((item) => {
        const start = item.start.split(':')
        const end = item.end.split(':')
        var startMinute = parseInt(start[0]) * 60 + parseInt(start[1])
        var endMinute = parseInt(end[0]) * 60 + parseInt(end[1])
        if (startMinute > endMinute) return
        startMinute = startMinute < 0 ? 0 : startMinute
        if (startMinute < minStartMinute) {
          minStartMinute = startMinute
        }
        endMinute = endMinute > 1440 ? 1440 : endMinute
        return {
          start: parseInt(start[0]) * 60 + parseInt(start[1]),
          end: parseInt(end[0]) * 60 + parseInt(end[1]),
        }
      })
      this.updateTimeCursor(minStartMinute)
      return timeSlice
    },
    timeMinute() {
      return 1440 / this.step
    },
  },
  created() {
    this.resizeDo = debounce(this.resize, 200)
  },
  mounted() {
    this.timeRuleWidth = this.$refs.timeRule.offsetWidth
    this.addMouseEvents()
    this.addScrollEvents()
  },
  methods: {
    // 视频进度逻辑
    timeChange(seconds) {
      if (this.isDraggingc) return
      const dx = Math.floor(seconds / 60)
      const cursorPosition = this.cursorPosition
      if (dx <= this.timeChangeOld) return
      let newLeft = cursorPosition + 1
      // 限制cursor在timeRule的范围内
      if (newLeft < 0) newLeft = 0
      if (newLeft > 1440) newLeft = 1440
      this.updateTimeCursor(newLeft)
      this.timeChangeOld = dx
    },
    // 修改时间和指针位置
    updateTimeCursor(position) {
      const cursor = this.$refs.cursor
      const minutes = this.updateCursorText(position)
      cursor.style.left = `${minutes}px`
      this.cursorPosition = minutes
    },
    // 处理时间片段
    actionTimeSlice(minute) {
      if (!this.timeSliceFormat.length) return false
      return this.timeSliceFormat.some((item) => {
        return minute >= item.start && minute < item.end
      })
    },
    resize(data) {
      this.timeRuleWidth = data[0].contentRect.width
    },
    addMouseEvents() {
      const cursor = this.$refs.cursor
      cursor.addEventListener('mousedown', this.onMouseDown)
    },
    addScrollEvents() {
      const timeRule = this.$refs.timeRule
      timeRule.addEventListener('mousedown', this.onScrollMouseDown) //this.$refs.cursor 的mousedown正在执行的时候，它不能执行
    },
    // 触发更改时间的事件
    emitChangeTime() {
      // 将this.cursorText转化为分钟，判断它是否在时间片段中
      const minutes =
        parseInt(this.cursorText.split(':')[0]) * 60 +
        parseInt(this.cursorText.split(':')[1])
      const isTimeSlice = this.actionTimeSlice(minutes)
      if (isTimeSlice) {
        this.$emit('change', this.cursorText)
      }
    },
    onMouseDown(event) {
      this.isDraggingc = true
      const cursor = this.$refs.cursor
      const clientX = event.clientX
      const cursorPosition = this.cursorPosition
      var newCursorPosition = cursorPosition
      const onMouseMove = (e) => {
        const dx = e.clientX - clientX
        let newLeft = cursorPosition + dx
        // 限制cursor在timeRule的范围内
        if (newLeft < 0) newLeft = 0
        if (newLeft > 1440) newLeft = 1440
        const minutes = this.updateCursorText(newLeft)
        cursor.style.left = `${minutes}px`
        newCursorPosition = minutes
      }

      const onMouseUp = () => {
        this.isDraggingc = false
        this.cursorPosition = newCursorPosition
        this.emitChangeTime()
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
    updateCursorText(position) {
      const minutes = Math.round(position / this.step) * this.step
      const hours = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0')
      const mins = (minutes % 60).toString().padStart(2, '0')
      this.cursorText = `${hours}:${mins}`
      return minutes
    },
    onScrollMouseDown(event) {
      if (this.isDraggingc) return
      this.isDragging = true
      this.startDragX = event.clientX
      this.startScrollLeft = this.$refs.timeRule.scrollLeft

      const onMouseMove = (e) => {
        if (!this.isDragging) return
        const dx = e.clientX - this.startDragX
        var startScrollLeft = this.startScrollLeft - dx
        if (startScrollLeft < 0) {
          startScrollLeft = 0
        }
        if (startScrollLeft > 1440 - this.timeRuleWidth) {
          startScrollLeft = 1440
        }
        this.$refs.timeRule.scrollLeft = startScrollLeft
      }

      const onMouseUp = () => {
        this.isDragging = false
        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
  },
  watch: {
    timeSlice: {
      handler() {
        //  每次修改，重置data数据
        const initialData = this.$options.data.call(this)
        Object.keys(initialData).forEach((key) => {
          this.$data[key] = initialData[key]
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<template>
  <div ref="timeRule" v-resize="resizeDo" class="time-rule">
    <div ref="timeDay" class="time-day">
      <div
        v-for="item in timeMinute"
        :key="item"
        :style="{ width: step + 'px' }"
        class="time-minute"
        :class="{ active: actionTimeSlice((item - 1) * step) }"
      ></div>
      <!-- 时间 -->
      <div
        v-for="item in 24"
        class="time-text"
        :key="item + 'minutes'"
        :class="{ 'time-text-first': item === 1 }"
      >
        {{ item <= 10 ? '0' + (item - 1) + ':00' : item - 1 + ':00' }}
      </div>
    </div>
    <div ref="cursor" class="time-cursor">
      <div class="time-cursor-text">{{ cursorText }}</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.time-rule {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  background-color: #ccc;
  font-size: 12px;
  height: 50px;
  overflow-x: auto;
  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }

  .time-day {
    width: 1440px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;

    .time-minute {
      height: 8px;
      float: left;
    }
    .time-minute.active {
      cursor: pointer;
      background-color: #404d59;
    }
    .time-text {
      float: left;
      width: 60px;
      border-left: 1px solid #999;
      border-top: 1px solid #999;
      text-align: center;
    }
    .time-text-first {
      border-left-width: 0px;
    }
  }

  .time-cursor {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    width: 2px;
    background-color: #f03;
    text-align: center;
    .time-cursor-text {
      position: absolute;
      padding: 0 5px;
      width: 60px;
      height: 15px;
      cursor: move;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      border: 1px solid #f03;
      left: -30px;
      top: 30px;
      background-color: #fff;
      line-height: 15px;
    }
  }
}
</style>
