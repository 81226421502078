/**
 * @author lixidong
 * @description 自定义项目常用的快捷方法
 */

import crypto from 'crypto-js'

/**
 * @description 解析json字符串，转换对象的健（大转小且_）
 */

export const transformJson = (json, filter) => {
  if (!json) return {}
  try {
    const obj = typeof json === 'string' ? JSON.parse(json) : json
    const transformedObject = {}
    Object.keys(obj).forEach((key) => {
      let newKey = ''
      if (filter && typeof filter[key] === 'function') {
        newKey = filter[key](key)
      } else {
        const restChars = key.replace(
          /[A-Z]/g,
          (match) => `_${match.toLowerCase()}`
        )
        newKey = restChars.slice(1)
      }
      transformedObject[newKey] = obj[key]
    })
    return transformedObject
  } catch (err) {
    return {}
  }
}

/**
 * @description 微服务路径选择器
 * @param {*} url
 * @param {*} urls
 * @returns  urls=>item
 */
export const serverUrlHelper = (url, urls) => {
  let urlItem = urls.find((item) => item.transitionReg.test(url))
  return urlItem
}

/**
 * 字段判断规范化处理
 * @param {Number} value
 * @param {String} value
 */
export const ifspecification = (value, ifValue) => {
  const stringValue = String(value)
  const stringIfValue = String(ifValue)

  if (/^-?\d*\.?\d+$/.test(stringValue)) {
    return stringValue === stringIfValue
  }

  return stringValue.toUpperCase() === stringIfValue.toUpperCase()
}

/**
 * 防抖
 * @param {Function} func 执行函数
 * @param {*} delay  间隔时间
 * @returns
 */
export const debounce = (func, delay) => {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, delay)
  }
}
/***
 * 节流
 */

export const throttle = (func, wait) => {
  let timeout = null
  let lastTime = 0

  return function () {
    const context = this
    const args = arguments
    const now = new Date().getTime()

    if (now - lastTime >= wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      func.apply(context, args)
      lastTime = now
    } else if (!timeout) {
      timeout = setTimeout(function () {
        func.apply(context, args)
        lastTime = new Date().getTime()
      }, wait - (now - lastTime))
    }
  }
}
// 判断是否为同一天
export const isSameDay = (date1, date2) => {
  const d1 = date1.getDate()
  const m1 = date1.getMonth()
  const y1 = date1.getFullYear()

  const d2 = date2.getDate()
  const m2 = date2.getMonth()
  const y2 = date2.getFullYear()

  return d1 === d2 && m1 === m2 && y1 === y2
}

/**
 *  生成唯一id
 * @param {String/Number} key
 * @returns String
 * @example
 * // uuid(1)
 */
export const uuid = (key = '') => {
  return (
    Date.now().toString(36) + Math.random().toString(36).substr(2) + (key + '')
  )
}

/**
 * md5加密
 * @param {String/Number} text
 * @returns String
 */

export const md5 = (text) => {
  return crypto.MD5(text).toString()
}

/***
 * videojs 检测
 */

// 是否是移动设备
export const isMobile = () => {
  return videojs.browser.IS_IOS || videojs.browser.IS_ANDROID
}

// 是否是ie
export const isIE = () => {
  return !!videojs.browser.IE_VERSION
}

// 表单前后空格处理
export const trimmedForm = (form) =>
  Object.fromEntries(
    Object.entries(form).map(([key, value]) => [
      key,
      typeof value === 'string' ? value.trim() : value,
    ])
  )
