<script>
/**
 * @author lixidong
 * @description 功能导览
 */
export default {
  props: {
    title: { type: String, default: '确认开启当前页面导览功能？' },
  },
}
</script>

<template>
  <el-popover
    placement="top-start"
    title="功能介绍"
    width="200"
    trigger="hover"
  >
    <slot />
    <i class="el-icon-info" slot="reference"></i>
  </el-popover>
</template>
