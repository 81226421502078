<script>
/**
 * @author lixidong
 * @description 边缘配置
 */
import AdminNoLTE from '@/components/AdminLTE/AdminNoLTE.vue'
import NSider from '@/components/NSider.vue'
import WarmingBox from './warmingBox.vue'
import * as nodeExporter from '@/api/node_exporter'
import tableMixin from './utils/table.js'
import getChannlInfo from './utils/getChannlInfo'
export default {
  mixins: [tableMixin, getChannlInfo],
  components: { AdminNoLTE, NSider, WarmingBox },
  data() {
    return {
      nodeExporterInstance: null,
      value: {
        cpu: null,
        memory: null,
        disk: null,
      },
    }
  },
  mounted() {
    this.$refs.tableTool && this.$refs.tableTool.requestTableData()
    this.$bus.$on('warning-message', () => {
      this.$refs.tableTool && this.$refs.tableTool.requestTableData()
    })
  },
  methods: {
    // 获取盒子信息
    getBoxInfo(data) {
      var { Addr: nodeExporterInstance } = data
      this.channelInfo(data)
      this.nodeExporterInstance = nodeExporterInstance
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => this.updateValue(), 1000 * 60)
      this.updateValue()
    },
    async updateValue() {
      var nodeExporterInstance = this.nodeExporterInstance
      if (!nodeExporterInstance) return
      nodeExporterInstance += ':9100'
      // CPU 使用率查询
      const cpuQuery = `100 - (avg by (cpu) (rate(node_cpu_seconds_total{mode="idle",instance="${nodeExporterInstance}"}[1m])) * 100)`
      // 内存使用率查询
      const memoryQuery = `(1 - ((node_memory_MemFree_bytes{instance="${nodeExporterInstance}"} + node_memory_Inactive_bytes{instance="${nodeExporterInstance}"}) / node_memory_MemTotal_bytes{instance="${nodeExporterInstance}"})) * 100`
      // 硬盘使用率查询
      const diskQuery = `(1 - node_filesystem_free_bytes{instance="${nodeExporterInstance}"} / node_filesystem_size_bytes{instance="${nodeExporterInstance}"}) * 100`
      const PromiseArr = [cpuQuery, memoryQuery, diskQuery].map((query) => {
        return nodeExporter.getNodeExporter(query)
      })
      let res = await Promise.all(PromiseArr)
      const result = res.map((item, index) => {
        if (item.code == 200) {
          try {
            if (index == 0) {
              // cpu
              let time = item.data.result[0].value[0]
              // 所有cpu的平均值
              let value =
                item.data.result.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.value[1] * 1,
                  0
                ) / item.data.result.length
              return [time, value]
            }
            return item.data.result[0].value
          } catch (err) {
            return []
          }
        } else {
          return []
        }
      })
      this.value = {
        cpu:
          result[0].length > 0
            ? parseFloat(Math.max(0, result[0][1] * 1).toFixed(2))
            : 0,
        memory:
          result[1].length > 0
            ? parseFloat(Math.max(0, result[1][1] * 1).toFixed(2))
            : 0,
        disk:
          result[2].length > 0
            ? parseFloat(Math.max(0, result[2][1] * 1).toFixed(2))
            : 0,
      }
    },
  },

  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  },
}
</script>

<template>
  <AdminNoLTE mode>
    <NSider slot="sider">
      <WarmingBox @getBoxInfo="getBoxInfo" @getChannlInfo="channelInfo" />
    </NSider>
    <div class="edge-configuration">
      <el-card class="edge-configuration_card">
        <el-row class="edge-configuration_row" type="flex" :gutter="20">
          <el-col :span="12">
            <el-row class="edge-configuration_row" type="flex" :gutter="20">
              <el-col style="width: auto">盒子状态</el-col>
              <el-col style="width: auto"
                >CPU: <span>{{ value.cpu || '0.00' }}%</span>
              </el-col>
              <el-col style="width: auto"
                >内存: <span>{{ value.memory || '0.00' }}%</span>
              </el-col>
              <el-col style="width: auto"
                >硬盘: <span>{{ value.disk || '0.00' }}%</span>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <el-row class="edge-configuration_row" type="flex" :gutter="20">
              <el-col style="width: auto"
                >通道: 共<span>{{ nowPipelines.total }}</span
                >路</el-col
              >
              <el-col style="width: auto"
                ><el-tag type="primary" size="mini" plain>运行</el-tag>
                {{ nowPipelines.run }}路</el-col
              >
              <el-col style="width: auto"
                ><el-tag type="danger" size="mini" plain>停止</el-tag>
                {{ nowPipelines.stop }}路</el-col
              >
            </el-row>
          </el-col>
        </el-row>
      </el-card>
      <div class="edge-configuration_table">
        <table-tool ref="tableTool" :tableData="tableData">
          <!-- 字段 -->
          <template slot="slot-AIBoxName" slot-scope="scope">
            <div>
              <div class="ellipsis">
                {{ scope.row.AIBoxName }}
              </div>
              <div class="ellipsis">{{ scope.row.Ip || '192.168.1.0' }}</div>
            </div>
          </template>
          <template slot="slot-CameraIP" slot-scope="scope">
            <div>
              <el-tooltip
                effect="dark"
                :content="scope.row.CameraIP"
                placement="right"
              >
                <div class="ellipsis">
                  {{ scope.row.CameraIP }}
                </div>
              </el-tooltip>
              <div class="ellipsis">{{ scope.row.Warnlevel }}</div>
            </div>
          </template>
          <template slot="slot-Snapshot" slot-scope="scope">
            <el-popover placement="right" trigger="hover">
              <div>
                <p>{{ scope.row.AIBoxName }}</p>
                <el-image style="width: 300px" :src="scope.row.Snapshot">
                  <!-- <img
                    style="width: 200px !important; height: auto"
                    slot="error"
                    :src="randomImage()"
                    alt=""
                  /> -->
                </el-image>
              </div>
              <el-image
                :src="scope.row.Snapshot"
                slot="reference"
                style="width: 120px; height: 60px"
                fit="fill"
              >
                <!-- <img
                  style="width: 100%; height: auto"
                  slot="error"
                  :src="randomImage()"
                  alt=""
                /> -->
              </el-image>
            </el-popover>
          </template>
          <template slot="slot-a" slot-scope="scope">
            <div>
              <div class="ellipsis">
                杨权-18864585875
                <el-tag size="mini" type="success">已读</el-tag>
              </div>
              <div class="ellipsis">
                杨广-18371180133
                <el-tag size="mini" type="warning">未读</el-tag>
              </div>
            </div>
          </template>
          <template slot="slot-b" slot-scope="scope">
            <div>
              <el-tag size="mini" type="success">已处理</el-tag>
            </div>
          </template>
          <!-- 按钮 -->
          <template slot="slot-info" slot-scope="scope">
            <el-row :gutter="10">
              <el-col :span="8">
                <el-tooltip content="截图下载" placement="left">
                  <el-link
                    :underline="false"
                    icon="el-icon-picture-outline"
                    type="success"
                    size="mini"
                    @click="download(scope.row)"
                  ></el-link>
                </el-tooltip>
              </el-col>
              <el-col :span="8">
                <el-tooltip content="录像下载" placement="left">
                  <el-link
                    :underline="false"
                    icon="el-icon-video-camera-solid"
                    type="success"
                    size="mini"
                    @click="download(scope.row)"
                  ></el-link>
                </el-tooltip>
              </el-col>
              <el-col :span="8">
                <el-tooltip content="源视频流地址" placement="left">
                  <el-link
                    :underline="false"
                    icon="el-icon-video-play"
                    type="primary"
                    size="mini"
                    @click="playVidoe(scope)"
                  ></el-link>
                </el-tooltip>
              </el-col>
              <el-col :span="8">
                <el-tooltip content="分析后视频流地址" placement="left">
                  <el-link
                    :underline="false"
                    icon="el-icon-video-play"
                    type="primary"
                    size="mini"
                    @click="playVidoe(scope)"
                  ></el-link>
                </el-tooltip>
              </el-col>
              <el-col :span="8">
                <el-tooltip content="详情" placement="left">
                  <el-link
                    :underline="false"
                    icon="el-icon-warning-outline"
                    type="info"
                    size="mini"
                    @click="infoChannel(scope.row)"
                  ></el-link>
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </table-tool>
      </div>
    </div>
    <Warm ref="warm" />
    <DialogPlayer ref="dialog-player" />
  </AdminNoLTE>
</template>

<style scoped lang="less">
.edge-configuration {
  position: relative;

  .edge-configuration_card {
    margin-bottom: 15px;
    height: 70px;
    box-sizing: border-box;
    overflow: hidden;
    .edge-configuration_row {
      line-height: 30px;
      white-space: nowrap;
      overflow: hidden;
      flex-wrap: nowrap;
    }
  }
  .edge-configuration_table {
    height: calc(100% - 85px) !important;
  }
}
</style>
