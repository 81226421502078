<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
export default {
  data() {
    return {
      value: [
        { name: '主服务器', status: 'success' },
        { name: '智能盒子1', status: 'error' },
        { name: '智能盒子2', status: 'warming' },
        { name: '智能盒子3', status: 'success' },
      ],
    }
  },
}
</script>

<template>
  <div class="threeD-serve">
    <div class="threeD-detail-header">
      <div>服务器信息</div>
      <div>详情</div>
    </div>
    <div class="threeD-detail-main">
      <el-row v-for="(item, index) in value" :key="index">
        <el-col class="ellipsis" :span="12">{{ item.name }}</el-col>
        <el-col class="threeD-detail-main_status ellipsis" :span="12">
          <span
            :style="{
              color:
                item.status == 'success'
                  ? 'var(--color-broccoli)'
                  : item.status == 'warming'
                  ? 'var(--color-yellow)'
                  : 'var(--color-red)',
            }"
            >{{
              item.status == 'success'
                ? '运行'
                : item.status == 'warming'
                ? '告警'
                : '停止'
            }}</span
          >
          <span
            :style="{
              backgroundColor:
                item.status == 'success'
                  ? 'var(--color-broccoli)'
                  : item.status == 'warming'
                  ? 'var(--color-yellow)'
                  : 'var(--color-red)',
            }"
            class="threeD-serve-circle"
          ></span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="less" scoped>
.threeD-detail-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .threeD-detail-main_status {
    display: flex;
    align-items: center;

    .threeD-serve-circle {
      margin-left: 15px;
      @wh: 16px;
      width: @wh;
      height: @wh;
      border-radius: 50%;
      display: inline-block;
      box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
