/**
 * @author lixidong
 * @description 视频录接口
 * @link http://192.168.1.168:8090/swagger/index.html#/devices/get_devices
 */
import { get, post, del } from './index'

/**
 * 监控播放（直播/回放）
 * @param {Object} data  channelid ; replay是否回放，1回放，0直播，默认0; start 回放开始时间，时间戳，replay=1时必传;回放结束时间，时间戳，replay=1时必传;
 * @description 直播一个通道最多存在一个流，回放每请求一次生成一个流
 * @returns
 */
export const watchplaystream = (data) => {
  return post(`/gosip_server/api/v1/channels/${data.channelid}/streams`, data)
}

/**
 *  停止直播/回放
 * @param {Object} data streamid;
 * @description 无人观看5分钟自动关闭，直播流无需调用此接口。
 * @returns
 */
export const stopplaystream = (data) => {
  return del(`/gosip_server/api/v1/stream/${data.streamid}`)
}

// 视频流列表接口
export const streamlist = (data) => {
  return get('/gosip_server/api/v1/stream', data)
}

/**
 * 回放文件时间列表
 * @param {Object} data channelid;start 开始时间戳 ； end 结束时间戳
 * @description 用来获取通道设备存储的可回放时间段列表，注意控制时间跨度，跨度越大，数据量越多，返回越慢，甚至会超时（最多10s）。
 */
export const recordstimelist = (data) => {
  const { channelid, ...datas } = data
  return get(`/gosip_server/api/v1/channels/${data.channelid}/records`, datas)
}
