var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"threeD-serve"},[_vm._m(0),_c('div',{staticClass:"threeD-detail-main"},_vm._l((_vm.value),function(item,index){return _c('el-row',{key:index},[_c('el-col',{staticClass:"ellipsis",attrs:{"span":12}},[_vm._v(_vm._s(item.name))]),_c('el-col',{staticClass:"threeD-detail-main_status ellipsis",attrs:{"span":12}},[_c('span',{style:({
            color:
              item.status == 'success'
                ? 'var(--color-broccoli)'
                : item.status == 'warming'
                ? 'var(--color-yellow)'
                : 'var(--color-red)',
          })},[_vm._v(_vm._s(item.status == 'success' ? '运行' : item.status == 'warming' ? '告警' : '停止'))]),_c('span',{staticClass:"threeD-serve-circle",style:({
            backgroundColor:
              item.status == 'success'
                ? 'var(--color-broccoli)'
                : item.status == 'warming'
                ? 'var(--color-yellow)'
                : 'var(--color-red)',
          })})])],1)}),1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"threeD-detail-header"},[_c('div',[_vm._v("服务器信息")]),_c('div',[_vm._v("详情")])])
}]

export { render, staticRenderFns }