/**
 * @author lixidong
 * @description index.html 路由
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { beforeEach } from './guard'
Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
})
router.beforeEach(beforeEach)

export default router
