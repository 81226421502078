
/**
 * @author lixidong
 * @description 系统配置
 */
import { put ,get,del,post,upload} from './index';


// 播放配置
export const play_configuration = () => {
    return get('/gosip_server/api/v1/configuration/play')
}

// 更新播放配置
export const  update_play_configuration=data=>{
    return put('/gosip_server/api/v1/configuration/play',data)
}

// 信令服务配置
export const signalling_configuration=()=>{
    return get('/gosip_server/api/v1/configuration/signalling')
}

// 更新信令配置
export const update_signalling_configuration=data=>{
    return put('/gosip_server/api/v1/configuration/signalling',data)
}

// 媒体流配置
export const streaming_configuration=()=>{
    return get('/gosip_server/api/v1/configuration/streaming')
}
// 更新流媒体配置
export const update_streaming_configuration=data=>{
    return put('/gosip_server/api/v1/configuration/streaming',data)
}

// 获取黑白名单
export const black_and_white_list_select=data=>{
    return get('/gosip_server/api/v1/configuration/black_and_white_list',data)
}

// 添加黑白名单
export const black_and_white_list_insert=data=>{
    return post('/gosip_server/api/v1/configuration/black_and_white_list',data)
}

// 修改黑白名单
export const black_and_white_list_update=data=>{
    return put(`/gosip_server/api/v1/configuration/black_and_white_list/${data.id}`,data)
}

// 删除黑白名单
export const black_and_white_list_delete=data=>{
    return del(`/gosip_server/api/v1/configuration/black_and_white_list/${data.id}`)
}

// 上传文件
export const uploadFile=data=>{
    return upload('/gosip_server/api/v1/upload',data)
}