import { v4 } from 'uuid'
const requireVideo = require.context(
  // 目标目录的相对路径
  '@/mock/videos',
  // 是否查询其子目录
  false,
  // 匹配文件的正则表达式
  /\.mp4$/
)
const requirePng = require.context(
  // 目标目录的相对路径
  '@/mock/videos',
  // 是否查询其子目录
  false,
  // 匹配文件的正则表达式
  /\.png$/
)

const requireData = require.context(
  // 目标目录的相对路径
  '@/mock/videos',
  // 是否查询其子目录
  false,
  // 匹配文件的正则表达式
  /\.js$/
)

export const videos = requireVideo.keys().map((fileName) => {
  return {
    name: fileName.replace('./', ''),
    path: requireVideo(fileName),
    png: requirePng(fileName.replace('.mp4', '.png')),
    data: requireData(fileName.replace('.mp4', '.js')).default,
  }
})

export const channels = videos.map((item) => {
  const channelid = v4()
  return {
    id: v4(),
    addtime: 1717482194,
    uptime: 1722328981,
    channelid: channelid,
    deviceid: '37070000081118000002',
    memo: '',
    name: item.data.Location,
    manufacturer: 'Manufacturer',
    model: 'Camera',
    owner: 'Owner',
    civilcode: 'CivilCode',
    address: '192.168.1.134',
    parental: 0,
    safetyway: 0,
    registerway: 1,
    secrecy: 0,
    status: 'ON',
    active: 1722328981,
    uri: 'sip:37070000081318000034@3707000008',
    vf: '',
    height: 0,
    width: 0,
    fps: 0,
    streamtype: 'push',
    url: '',
    streams: {
      id: v4(),
      addtime: 1722326220,
      uptime: 1722326221,
      t: 0,
      deviceid: '37070000081118000002',
      channelid: channelid,
      streamtype: 'push',
      status: 0,
      callid: 'ol7cUF6QZst1thtAXcAQCt10Am5uAOch',
      stop: false,
      msg: '',
      cseqno: 2,
      streamid: '29B929A6',
      http: item.path,
      rtmp: item.path,
      rtsp: item.path,
      wsflv: item.path,
      stream: false,
    },
  }
})
