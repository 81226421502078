export default {
  inserted: function (el, binding) {
    var realSrc = binding.value
    var img = new Image()
    img.src = realSrc

    img.onload = function () {
      binding.arg == 'backgroundImage'
        ? (el.style.backgroundImage = `url(${realSrc})`)
        : (el.src = realSrc)
    }
  },
}
