<script>
/**
 * 单通道视频播放
 * @author lixdiong
 */
import Liveplayer from './liveplayer.vue'
import channels from './mixins/channels'
import * as streamApi from '@/api/stream_api.js'
export default {
  props: {
    mode: Boolean, // 多视频
    // 唯一标识 mode为true有效
    id: {
      type: Number / String,
      default: 0,
    },
    // 视频窗口 宽高比
    liveplayeraspect: {
      type: String,
      value: 'fullscreen',
    },
    live: { type: Boolean, default: true },
  },
  components: { Liveplayer },
  mixins: [channels],
  data() {
    return {
      rawdata: null, //其他参数
      streamlist: null, //协议地址列表
      streamtype: null, //播放协议类型
    }
  },
  computed: {
    // 播放视频地址
    streamUrl() {
      if (!this.streamlist || !this.streamtype) return ''
      let stream = this.streamlist.find((item) => item.type == this.streamtype)
      if (stream) return stream.url
      return ''
    },
  },
  methods: {
    // 视频进度
    timeupdate(time) {
      this.$emit('timeupdate', time)
    },
    // 通道点击事件
    async handleChannelClick(data, resetCallback) {
      // 判断是否更新
      if (resetCallback) {
        if (resetCallback()) return
      } else {
        // 多屏模式下
        if (this.mode && this.rawdata) return false
        if (this.rawdata && this.rawdata.channelid === data.channelid)
          return false
      }
      // 直播
      let res = await streamApi.watchplaystream(data)
      if (res.code === 200) {
        const { http, rtmp, wsflv, ...rawdata } = res.data
        this.rawdata = { ...rawdata, name: data.name }
        const streamlist = [
          { type: 'HLS', url: http },
          { type: 'WS-FLV', url: wsflv },
          { type: 'RTMP', url: rtmp },
        ]
        let active_item = streamlist.find((item) => item.url)
        this.streamlist = streamlist
        this.streamtype = active_item.type
        // 曝露一些数据源供调用者使用
        this.mode
          ? this.$emit('initDataCallback', this.id, rawdata, streamlist)
          : this.$emit(
              'initDataCallback',
              rawdata,
              streamlist,
              active_item.type
            )
        return true
      }
      return false
    },
    // 切换协议类型
    changeStreamTypeRef(streamtype) {
      this.streamtype = streamtype
    },
    // 关闭通道
    async closeChannel() {
      // await streamApi.stopplaystream(this.rawdata);
      this.rawdata = null
      this.streamlist = null
      this.streamtype = null
      // 关闭通道回调
      this.mode
        ? this.$emit('destoryDataCallback', this.id)
        : this.$emit('destoryDataCallback')
    },
  },
}
</script>

<template>
  <div class="channel-player">
    <!-- 视频 -->
    <Liveplayer
      :live="live"
      :videoTitle="rawdata ? rawdata.name : ''"
      :aspect="liveplayeraspect"
      :videoUrl="streamUrl"
      @closeChannel="closeChannel"
      @selectChannel="dialogShow"
      @timeupdate="timeupdate"
    />
    <!-- 通道弹窗 -->
    <el-dialog
      top="100px"
      append-to-body
      title="视频通道"
      width="800px"
      :visible.sync="dialogVisible"
    >
      <table-tool ref="tableTool" :tableData="tableData"></table-tool>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.channel-player {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
