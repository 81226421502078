<script>
/**
 * 回放
 * @author lixidong
 */
import * as streamApi from '@/api/stream_api.js'
import Player from '@/components/Player/index.vue'
import TimeRule from './timeRule.vue'
import moment from 'moment'
import channels from './utils/channels.js'
import { throttle, isSameDay } from '@/utils/tool.js'
export default {
  name: 'PlayBack',
  components: { Player, TimeRule },
  mixins: [channels],
  data() {
    return {
      datepicker: '',
      channelId: null,
      channelName: '',
      options: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      timestreamp: false,
      playerShow: true,
      timeSlice: [],
      btnLoad: false,
    }
  },
  created() {
    this.timeupdateToTimeRule = throttle((seconds) => {
      this.$refs.TimeRule.timeChange && this.$refs.TimeRule.timeChange(seconds)
    }, 1000)
  },
  methods: {
    selectChannel(data) {
      this.channelName = data.name
      this.channelId = data.channelid
    },
    // 拨动时间轴
    changeTime(timeText) {
      const day = new Date(this.datepicker)
      let startDate = new Date(day)
      startDate.setHours(
        parseInt(timeText.split(':')[0]),
        parseInt(timeText.split(':')[1]),
        0
      )
      let newStartDate = Math.floor(startDate.getTime() / 1000)
      let newEndDate = 0
      // 从timeSlice中获取对应的时间段,筛选出结束时间
      for (let i = 0; i < this.timeSlice.length; i++) {
        // 开始时间
        let startTime = new Date(day)
        let group = this.timeSlice[i]
        startTime.setHours(
          parseInt(group.start.split(':')[0]),
          parseInt(group.start.split(':')[1]),
          0
        )
        // 结束时间
        let endTime = new Date(day)
        endTime.setHours(
          parseInt(group.end.split(':')[0]),
          parseInt(group.end.split(':')[1]),
          0
        )
        if (
          startDate.getTime() >= startTime.getTime() &&
          startDate.getTime() <= endTime.getTime()
        ) {
          newEndDate = Math.floor(endTime.getTime() / 1000)
          break
        }
      }
      if (newEndDate === 0) {
        return
      }
      let timeValue = newStartDate + '-' + newEndDate
      this.timeChange([startDate.getTime(), timeValue])
    },
    // 获取回放时间列表
    async requestPlayBack() {
      this.btnLoad = true
      try {
        const start = Math.ceil(this.datepicker.setHours(0, 0, 0) / 1000)
        // 结束时间，若果是当天则取当前时间，否则取当天的23:59:59
        const end = isSameDay(new Date(this.datepicker), new Date())
          ? Math.ceil(new Date().getTime() / 1000)
          : Math.ceil(this.datepicker.setHours(23, 59, 59) / 1000)
        // 查询
        let res = await streamApi.recordstimelist({
          channelid: this.channelId,
          start,
          end,
        })
        if (res.code === 200) {
          console.log(res)
          // 获取当天的时间片段
          let checkedDay = res.data.list.find(
            (day) =>
              day.date == moment(new Date(this.datepicker)).format('YYYY-MM-DD')
          )
          if (checkedDay) {
            let minStartTime = 0
            this.timeSlice = checkedDay.items.map((slice) => {
              if (slice.start * 1000 < minStartTime || minStartTime == 0) {
                minStartTime = slice.start * 1000
              }
              let startLice = moment(slice.start * 1000).format('HH:mm')
              let endLice = moment(slice.end * 1000).format('HH:mm')
              return { start: startLice, end: endLice }
            })
            // 播放视频
            this.changeTime(moment(minStartTime).format('HH:mm'))
          } else {
            this.timeSlice = []
          }
        }
      } finally {
        this.btnLoad = true
      }
    },
    // 观看指定时间段的视频
    async timeChange(value) {
      try {
        let start_end = value[1].split('-')
        // 播放视频
        let status = this.$refs.player.handleChannelClick(
          {
            channelid: this.channelId,
            replay: 1,
            start: start_end[0] * 1,
            end: start_end[1] * 1,
          },
          () => false
        )
        if (!status) {
          this.refreshPlayer()
        }
      } catch (err) {
        this.refreshPlayer()
      }
    },
    // 刷新视频区域
    refreshPlayer() {
      this.playerShow = false
      this.$nextTick(() => {
        this.playerShow = true
      })
    },
    // 视频进度
    timeupdate(seconds) {
      this.timeupdateToTimeRule(seconds)
    },
  },
}
</script>

<template>
  <div class="play-back">
    <div class="play-back_search">
      <div style="display: inline-block" @click="channelDialogShow">
        <el-input
          size="mini"
          placeholder="请选择通道"
          style="pointer-events: none"
          v-model="channelName"
        ></el-input>
      </div>
      <el-date-picker
        v-model="datepicker"
        class="play-back_picker"
        :picker-options="pickerOptions"
        size="mini"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
      <el-button
        class="play-back_button"
        type="primary"
        :disabled="!channelId || !datepicker"
        size="mini"
        :load="btnLoad"
        @click="requestPlayBack"
        >查询</el-button
      >
    </div>
    <div
      style="height: calc(100% - 100px); margin-bottom: 6px; overflow: hidden"
    >
      <Player
        v-if="playerShow"
        @timeupdate="timeupdate"
        ref="player"
        :live="false"
      />
    </div>
    <TimeRule
      ref="TimeRule"
      :step="1"
      :timeSlice="timeSlice"
      @change="changeTime"
    />
    <!-- 通道弹窗 -->
    <el-dialog
      top="100px"
      append-to-body
      title="通道列表"
      width="800px"
      :visible.sync="channelDialogVisible"
    >
      <table-tool
        noresize
        ref="channelTableTool"
        :tableData="channelTableData"
      ></table-tool>
    </el-dialog>
  </div>
</template>
<style lang="less">
.play-back {
  height: 100%;
  .play-back_search {
    .play-back_cascader-two,
    .play-back_cascader-one,
    .play-back_picker,
    .play-back_button {
      margin-bottom: 15px;
      margin-left: 8px;
    }
    .play-back_cascader-one {
      margin-left: 0px;
    }

    .play-back_cascader-two {
      min-width: 250px;
    }
  }
}
</style>
