/**
 * @author lixidong
 * @description 用户角色弹窗列表
 */
import * as userApi from '@/api/user_api.js'
export default {
  data() {
    return {
      roleDialogVisible: false,
      roleTableData: {
        pageSize: 10,
        stripe: true,
        border: false,
        height: 500,
        size: 'mini',
        cellClick: this.roleCellClick,
        reqIntercept: this.roleReqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'RoleName',
            label: '角色名称',
          },
          {
            prop: 'PermissionName',
            label: '权限',
          },
        ],
      },
    }
  },
  methods: {
    // 打开弹窗
    roleDialogShow() {
      this.roleDialogVisible = true
      this.$nextTick(
        () =>
          this.$refs.roleTableTool &&
          this.$refs.roleTableTool.requestTableData()
      )
    },
    // 通道数据请求拦截
    async roleReqIntercept(query) {
      let res = await userApi.loadrolelist({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: query.pageSize,
      })
      if (res.code === 200) {
        let { Total, Roles } = res.data
        return { data: Roles, total: Total }
      }
    },
    //  点击单元格
    async roleCellClick(row, column, cell, event) {
      this.dialogForm = Object.assign({}, this.dialogForm, {
        RoleName: row.RoleName,
        RoleID: row.ID,
      })
      this.roleDialogVisible = false
    },
  },
}
