/**
 * 通道弹窗
 * @auhtor lixidong
 */

import TableTool from '@/components/TableTool/index.vue'
import * as deviceApi from '@/api/device_api.js'
export default {
  components: { TableTool },
  data() {
    const streamtypelist = [
      {
        label: '媒体服务器拉流',
        value: 'pull',
      },
      {
        label: '摄像头推流',
        value: 'push',
      },
    ]
    return {
      dialogVisible: false,
      tableData: {
        stripe: true,
        border: false,
        height: 398,
        size: 'mini',
        cellClick: this.cellClick,
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'channelid',
            width: 160,
            label: 'ID',
          },
          {
            prop: 'name',
            label: '名称',
          },
          {
            prop: 'streamtype',
            label: '播放类型',
            type: 'select',
            value: streamtypelist,
          },
          {
            prop: 'url',
            label: '静态拉流地址',
          },
          {
            prop: 'memo',
            label: '备注',
          },
        ],
      },
    }
  },
  methods: {
    // 打开弹窗
    dialogShow() {
      this.dialogVisible = true
      this.$nextTick(
        () => this.$refs.tableTool && this.$refs.tableTool.handleSizeChange(10)
      )
    },
    // 通道数据请求拦截
    async reqIntercept(query) {
      let res = await deviceApi.channels_config({
        skip: (query.currentPage - 1) * query.pageSize,
        limit: query.pageSize,
      })
      if (res.code === 200) {
        let { Total, List } = res.data
        return { data: List, total: Total }
      }
    },
    //  点击单元格
    async cellClick(row, column, cell, event) {
      this.handleChannelClick(row)
      this.dialogVisible = false
    },
  },
}
