<script>
/**
 * @author lixidong
 * @description 用户管理
 */
// 表格模块
import TableTool from '@/components/TableTool/index.vue'
import tableMixin from './utils/user_table.js'
import roleMixin from './utils/role.js'
import InfoConfirm from '@/components/InfoConfirm/index.vue'
export default {
  components: { TableTool, InfoConfirm },
  mixins: [tableMixin, roleMixin],
}
</script>

<template>
  <div class="userm">
    <table-tool ref="tableTool" :tableData="tableData">
      <template slot="header">
        <auth-wrapper auth="add-user">
          <div class="userm_header">
            <div>
              <el-button
                class="userm_add-user"
                @click="dialogFormShow"
                type="primary"
                size="mini"
                icon="el-icon-plus"
                >添加用户</el-button
              >
            </div>
            <div>
              <info-confirm>
                <el-row
                  >1. 点击“添加用户”按钮，可以实现<span class="info-highlight"
                    >新增用户</span
                  >并且<span class="info-highlight">绑定拥有服务权限的角色</span
                  >。</el-row
                >
                <el-row
                  >2.
                  如果您拥有“编辑”和“删除”的权限，那么您就可以修改用户的相应参数。</el-row
                >
              </info-confirm>
            </div>
          </div>
        </auth-wrapper>
      </template>
      <template slot="expand" slot-scope="scope">
        <el-row style="padding: 20px" :gutter="10">
          <!-- 设备服务通知 -->
          <el-row :gutter="10">
            <el-col :span="4">设备服务通知</el-col>
            <el-col :span="20">
              <el-switch
                style="margin-right: 5px"
                v-model="scope.row.WaaoaaStatus"
                disabled
                size="mini"
              ></el-switch>
              <info-confirm>
                <el-row
                  >获取设备通知服务需要登录小程序，并且关注公众号。</el-row
                >
              </info-confirm>
            </el-col>
          </el-row>
          <!-- 组织结构 -->
          <el-row
            style="margin-top: 10px"
            v-if="scope.row.UserGroup && scope.row.UserGroup.length > 0"
          >
            <el-col :span="4">所属组织</el-col>
            <el-col :span="20">
              <el-tag
                style="margin-right: 5px"
                v-for="(item, index) in scope.row.UserGroup"
                :key="index"
                size="mini"
                >{{ item.GroupName }} - {{ item.RoleName }}</el-tag
              >
            </el-col>
          </el-row>
        </el-row>
      </template>
      <!-- 按钮操作 -->
      <template slot="slot-edit" slot-scope="scope">
        <auth-wrapper slot auth="update-user">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-edit"
              type="success"
              size="mini"
              @click.stop="editFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
      <template slot="slot-delete" slot-scope="scope">
        <auth-wrapper slot auth="delete-user">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              :underline="false"
              icon="el-icon-delete"
              type="danger"
              size="mini"
              @click.stop="deleteFn(scope.row)"
            ></el-link>
          </template>
        </auth-wrapper>
      </template>
    </table-tool>
    <!-- 添加修改用户 -->
    <el-dialog
      top="100px"
      :title="dialogTitle | dialogTitleKeyValue"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        size="mini"
        ref="dialogForm"
        :model="dialogForm"
        :rules="dialogRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名称" prop="UserName">
              <el-input
                v-model="dialogForm.UserName"
                placeholder="请输入用户名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户昵称" prop="NickName">
              <el-input
                v-model="dialogForm.NickName"
                placeholder="请输入用户登昵称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col style="margin-top: 20px" :span="12">
            <el-form-item label="电话号码" prop="Tel">
              <el-input v-model="dialogForm.Tel" placeholder="请输入电话号码">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col style="margin-top: 20px" :span="12">
            <el-form-item label="角色名称" prop="RoleName">
              <div @click="roleDialogShow">
                <el-input
                  placeholder="请选择角色名称"
                  style="pointer-events: none"
                  v-model="dialogForm.RoleName"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogFormCancel">取 消</el-button>
        <el-button
          size="mini"
          type="primary"
          :loading="dialogBtnLock"
          @click="dialogFormSubmit('dialogForm')"
          >确 定</el-button
        >
      </div>
      <!-- 通道弹窗 -->
      <el-dialog
        top="100px"
        append-to-body
        title="角色列表"
        width="800px"
        :visible.sync="roleDialogVisible"
      >
        <table-tool
          noresize
          ref="roleTableTool"
          :tableData="roleTableData"
        ></table-tool>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.userm {
  height: 100%;
  .userm_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .userm_header-form {
      display: flex;
      align-items: center;

      /deep/ .el-input {
        margin-right: 5px;
      }
    }
  }
}
</style>
