/**
 * @author lixidong
 * @description 导航 三级导航
 */
import routes from '@/router/Index/routes'
import * as userApi from '@/api/user_api'
const menu = {
  namespaced: true,
  state: {
    // 激活路由
    active: [],
    // 存储菜单配置
    initMenus: [],
    // 首页列表
    homeList: [],
  },
  mutations: {
    updateActive(state, active) {
      state.active = active
    },
    updateMenus(state, menus) {
      state.initMenus = menus
    },
  },
  getters: {
    //
    menus(state) {
      const initMenus = state.initMenus
        .filter((menu) => menu.ParentID == 0)
        .map((menu) => {
          // 分配
          let children = state.initMenus.filter(
            (child) => child.ParentID == menu.ID
          )
          // 排序
          children.sort((a, b) => a.Sort - b.Sort)
          return { ...menu, children }
        })
      // 排序
      initMenus.sort((a, b) => a.Sort - b.Sort)
      return initMenus
    },
    // 一级导航显示数据
    menuNavigate(state, getters, rootState) {
      const rootKeys = rootState.root.rootKeys
      const value = getters.menus.filter(
        ({ EName, Url }) => rootKeys.includes(EName) && !!Url
      )
      return value
    },
    // 二级导航显示数据
    listNaviate(state, getters, rootState) {
      const { active } = state
      const rootKeys = rootState.root.rootKeys
      if (active.length === 0) return []
      const listInitNaviate = getters.menus
        .find((menu) => menu.EName === active[0].name)
        ?.children.filter((menu) => rootKeys.includes(menu.EName))
      return listInitNaviate
    },
    // 面包屑
    breadCrumb(state) {
      const { active, initMenus } = state
      if (active.length === 0) return []
      return active.map(({ name, meta, $route }) => {
        let menu = initMenus.find((menu) => menu.EName == name)
        if (!menu) return null
        let title = menu.CName
        if (meta && meta.breadcrumb) {
          title = meta.breadcrumb(meta, $route)
        }
        return { title, name }
      })
    },
    // 区分目录和菜单以及排序
    menusTableValue(state, getters) {
      // 两级菜单逻辑
      let menus = getters.menus
      return menus
    },
  },
  actions: {
    //  监听路由 核心二级路由
    updateRoute({ state, commit }, route) {
      // const active = route.name ? buildingStructure(route) : []
      // commit('updateActive', active)
      const active = []
      const buildingStructure = (node, currentPath) => {
        if (node.name === route.name) {
          // 特殊情况-具有族谱
          if (node.meta.genealogy) {
            node.meta.genealogy.forEach((name) => currentPath.push({ name }))
          }
          currentPath.push(Object.assign({ $route: route }, node))
          currentPath.forEach((path) => active.push(path))
          return true
        }
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            if (buildingStructure(child, [...currentPath, node])) {
              return true
            }
          }
        }
      }

      for (const menu of routes) {
        if (buildingStructure(menu, [])) {
          break
        }
      }

      commit('updateActive', active)
    },
    // 获取菜单配置
    async getMenuConfig({ commit }) {
      let res = await userApi.getMenu()
      if (res.code === 200) {
        let menus = res.data || []
        commit('updateMenus', menus)
      }
    },
  },
}

export default menu
