<script>
/**
 * @author
 * @description 实现多行
 */
import * as cameraApi from '@/api/camera_api'
export default {
  props: {
    auth: Boolean,
    uuid: String,
    index: Number,
    cameras: Array,
    pipelines: Array,
    value: Object,
    ip: String,
  },
  inject: ['state'],
  data() {
    return {
      oldValue: null, //确保更新时候，stop的是老数据，start的是新数据。
      timer: null,
      time: 30 * 1000,
      selectedCamera: '', //相机
      selectedProfile: '', ///视频流
      selectedPipeline: '', //算法
      cameraFeatures: null,
      profiles: [],
      // 关于通道的其他信息
      other: {
        state: null, //状态
      },
    }
  },
  computed: {
    disabled() {
      return (
        this.selectedCamera === '' ||
        this.selectedProfile === '' ||
        this.selectedPipeline === ''
      )
    },
    cameraIsOnvif() {
      if (!this.cameraFeatures) {
        return false
      }
      return this.cameraFeatures.CameraType === 'Onvif'
    },
    type() {
      return this.state.type
    },
  },
  mounted() {
    // 将模块的this暴露给父组件
    this.$emit('getThis', this.uuid, this)
  },
  methods: {
    // 更新功能
    async updatePipeline() {
      try {
        let res = await this.stopPipeline()
        if (res.code != 200) return
        await this.startPipeline()
      } catch (err) {
        //  还原数据
        const newVal = this.oldValue
        this.selectedCamera = newVal['DeviceId']
        this.selectedProfile = newVal['DeviceProfile']
        this.selectedPipeline = newVal['PipelineId']
      }
    },
    // 启动功能
    async startPipeline() {
      try {
        const {
          boxip: AiboxAddr,
          id: AiboxId,
          boxname: Aiboxname,
        } = this.$parent.form
        const { PipelineInstanceId = '' } = this.value || {}
        const DeviceId = this.selectedCamera
        const { Name: DeviceName } = this.cameras.find(
          (item) => item.Id === DeviceId
        )
        const DeviceProfileName = this.selectedProfile
        const PipelineId = this.selectedPipeline
        const { Name: PipelineName, Version: PipelineVersion } =
          this.pipelines.find((item) => item.Id === PipelineId)
        const params = {
          AiboxAddr,
          AiboxId,
          Aiboxname,
          DeviceId,
          DeviceName,
          DeviceProfileName,
          PipelineId,
          PipelineInstanceId,
          PipelineName,
          PipelineVersion,
        }
        let res = await cameraApi.startPipeline(params)
        if (res.code != 200) return
        this.$parent.updateChannel(
          this.uuid,
          {
            DeviceId: DeviceId,
            DeviceProfile: DeviceProfileName,
            PipelineId: PipelineId,
            PipelineInstanceId: res.data.PipelineInstanceId,
          },
          true
        )
        this.$message.success(`通道${this.index + 1} 已启动！`)
      } catch (err) {
        throw new Error('通道启动失败')
      }
    },
    // 停止功能
    async stopPipeline() {
      try {
        const {
          boxip: AiboxAddr,
          id: AiboxId,
          boxname: Aiboxname,
        } = this.$parent.form
        const { PipelineInstanceId = '' } = this.value || {}
        const { DeviceId, PipelineId } = this.oldValue
        const params = {
          AiboxAddr,
          AiboxId,
          DeviceId,
          PipelineId,
          PipelineInstanceId,
        }
        let res = await cameraApi.stopPipeline(params)
        if (res.code == 200) {
          this.$message.success(`通道${this.index + 1} 已停止！`)
        }
        return res
      } catch (err) {
        throw new Error('通道停止失败')
      }
    },
    // 获取通道状态
    async getPipelineStatus() {
      if (!this.auth) return
      try {
        let res = await cameraApi.getPipelineStatus(
          this.ip,
          this.value.PipelineInstanceId
        )
        if (res.code == 200) {
          this.$set(this.other, 'state', res.data.state)
          this.t(30 * 1000)
        } else {
          throw new Error('')
        }
      } catch (err) {
        this.t(60 * 1000)
      }
    },
    // 状态计时器
    async t(time) {
      this.time = time
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(this.getPipelineStatus, this.time)
    },
    // 删除通道
    deletePipeline() {
      this.$confirm('确认删除通道吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          if (this.auth && this.type == 'edit') {
            let res = await this.stopPipeline()
            if (res.code != 200) return
          }
          this.$emit('delChannel', this.uuid)
        })
        .catch(() => {})
    },
    //根据相机获取视频流
    async updateProfiles(cameraName) {
      try {
        let result = await cameraApi.makeCameraUrl(cameraName, '/profiles')
        if (result.code === 200) {
          let { data } = result
          const profiles = data.Profiles
          this.profiles = profiles
          this.selectedProfile =
            profiles.length > 0 ? data.Profiles[0].Token : ''
        } else {
          this.selectedProfile = ''
          this.profiles = null
        }
      } catch (err) {
        this.selectedProfile = ''
        this.profiles = null
      }
    },
    // 相机选择
    async cameraSelectionChanged(value, selectedProfileCallback = null) {
      value = this.cameras.find((camera) => camera.Id == value)
      if (!value) {
        this.$message.error(`通道${this.index + 1} 不存在该相机`)
        throw new Error(`通道${this.index + 1} 不存在该相机`)
      }
      await this.updateCameraChanged(value.Name, selectedProfileCallback)
    },
    // 相机发生变化
    async updateCameraChanged(cameraName, selectedProfileCallback) {
      this.clearCameraInfo()
      await this.updateCameraFeatures(cameraName, selectedProfileCallback)
    },
    // 获取features
    async updateCameraFeatures(cameraName, selectedProfileCallback) {
      let result = await cameraApi.makeCameraUrl(cameraName, '/features')
      if (result.code === 200) {
        this.cameraFeatures = result.data
        if (this.cameraIsOnvif) {
          this.updateProfiles(cameraName, selectedProfileCallback)
        } else {
          this.$message.error(
            `通道${this.index + 1} 不存在相机类型“${
              this.cameraFeatures.CameraType || ' '
            }”`
          )
        }
      }
    },
    // 获取视频流
    async updateProfiles(cameraName, selectedProfileCallback) {
      try {
        let result = await cameraApi.makeCameraUrl(cameraName, '/profiles')
        if (result.code === 200) {
          let { data } = result
          const profiles = data.Profiles
          this.profiles = profiles
          // 给予默认值
          if (selectedProfileCallback) {
            // 先判断是否存在
            if (
              profiles.some(({ Token }) => Token == selectedProfileCallback)
            ) {
              this.selectedProfile = selectedProfileCallback
            } else {
              this.$message.error(
                `通道${this.index + 1} 的视频流并不在相机视频流列表中。`
              )
              this.selectedProfile = ''
            }
          } else {
            // 如果是edit模式，保证预防后端传空，并且实现新增还能默认选中
            if (this.type == 'edit' && this.value) {
              this.selectedProfile = selectedProfileCallback
            } else {
              // 默认选中第二个
              this.selectedProfile =
                profiles.length > 1
                  ? profiles[1].Token
                  : profiles.length > 0
                  ? profiles[0].Token
                  : ''
            }
          }
        } else {
          this.selectedProfile = ''
          this.profiles = []
        }
      } catch (err) {
        this.selectedProfile = ''
        this.profiles = []
      }
    },
    // 清除相机信息
    clearCameraInfo() {
      this.selectedProfile = ''
      this.profiles = []
      this.cameraFeatures = null
    },
    // 修改状态给予默认值
    async editStatusDefault(query) {
      try {
        const { selectedCamera, selectedProfile, selectedPipeline } = query
        this.cameraSelectionChanged(selectedCamera, selectedProfile)
        this.selectedCamera = selectedCamera
        this.selectedPipeline = selectedPipeline
      } catch (err) {}
    },
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer)
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.editStatusDefault({
            selectedCamera: newVal['DeviceId'],
            selectedProfile: newVal['DeviceProfile'],
            selectedPipeline: newVal['PipelineId'],
          })
          this.oldValue = Object.assign({}, newVal)
          if (this.timer) clearTimeout(this.timer)
          this.getPipelineStatus()
        }
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div
    :data-index="'通道' + (index + 1)"
    class="selector-item"
    :class="{ active: auth }"
  >
    <el-form size="small" label-width="80px">
      <el-row class="selector-item_row" :gutter="5">
        <el-col :span="4">
          <el-form-item label="相机">
            <el-select
              v-model="selectedCamera"
              @change="cameraSelectionChanged"
            >
              <el-option
                v-for="camera in cameras"
                :key="camera.Id"
                :value="camera.Id"
                :label="camera.Name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="cameraIsOnvif" :span="4">
          <el-form-item label="视频流">
            <el-select v-model="selectedProfile">
              <el-option
                v-for="(profile, index) in profiles"
                :key="index"
                :value="profile.Token"
                :label="
                  profile.Token +
                  ' （' +
                  profile.VideoEncoderConfiguration.Resolution.Width +
                  'x' +
                  profile.VideoEncoderConfiguration.Resolution.Height +
                  ' ' +
                  profile.VideoEncoderConfiguration.Encoding +
                  ' @ ' +
                  profile.VideoEncoderConfiguration.RateControl.FrameRateLimit +
                  'fps）'
                "
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="算法">
            <el-select v-model="selectedPipeline">
              <el-option
                v-for="(pipeline, index) in pipelines"
                :key="index"
                :value="pipeline.Id"
                :label="pipeline.Name + ' - ' + pipeline.Version"
              >
              </el-option>
            </el-select> </el-form-item
        ></el-col>
        <el-col style="display: flex" :span="8">
          <el-tag
            style="
              margin: 0 5px;
              padding: 9px 15px;
              line-height: 1;
              height: auto;
            "
            size="small"
            type="info"
            v-if="other.state"
            >{{ other.state }}</el-tag
          >
          <el-button
            size="small"
            type="success"
            :disabled="disabled"
            v-if="type == 'edit' && auth"
            @click="updatePipeline"
            >更新</el-button
          >
          <el-button
            size="small"
            type="success"
            @click="startPipeline"
            :disabled="disabled"
            v-if="type == 'edit' && !auth"
            >启动</el-button
          >
          <el-button
            size="small"
            type="danger"
            :disabled="auth && disabled"
            @click="deletePipeline"
            >删除</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style lang="less" scoped>
.selector-item {
  position: relative;

  .selector-item_row {
    border: 1px dashed #909399;
    padding-top: 18px;
    margin: 0 !important;
    margin-bottom: 18px !important;
  }

  &::before {
    content: attr(data-index);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 3px 15px 3px 10px;
    background: #909399;
    color: #fff;
    font-size: 12px;
    border-radius: 0px 0px 20px 0px;
  }
}

.selector-item.active {
  &::before {
    background: #67c23a;
  }
}
</style>
