<script>
/**
 * @author lixidong
 * @description 播放控制
 */
import { Copy } from '@icon-park/vue'
// 表格模块
import TableTool from "@/components/TableTool/index.vue";
import FileAdditionIcon from "@/components/Icon/FileAddition.vue";
export default {
    components: { Copy, TableTool, FileAdditionIcon },
    props: {
        videoscheam: String,
        playervalue: Array,
        playerurl: String,
        changeStreamType: {
            type: Function,
            default: () => { }
        }
    },
    data () {
        return {
            // 表格数据
            tableData: {
                stripe: true,
                border: false,

                size: "mini",
                columns: [
                    {
                        prop: "name",
                        label: "名称",
                        slot: true,
                        template: () => {
                            return `<span>789</span>`;
                        },
                    },
                    {
                        prop: "token",
                        label: "Token",
                    },
                ],

                btn: {
                    label: "操作",
                    value: [
                        {
                            icon: "el-icon-edit",
                            type: 'text'
                        },
                        {
                            icon: "el-icon-delete",
                            type: 'text',

                        },
                        {
                            icon: "el-icon-share",
                            type: 'text',
                        },
                    ],
                },
            },
        }
    },
    methods: {
        copy (value) {
            this.$copyText(value).then(e => this.$message.success('复制成功'), e => this.$message.error('复制失败'))
        }
    }
}
</script>

<template>
    <div class="play-control">
        <el-form label-position="left" label-width="80px" size="mini">
            <el-row :gutter="20">
                <el-col v-if="playervalue && playervalue.length > 0" :span="24">
                    <el-form-item label="码流切换">
                        <el-radio-group @input="changeStreamType" :value="videoscheam">
                            <el-radio-button v-for="item in playervalue" :key="item.type" :disabled="!item.url"
                                :label="item.type"></el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="码流地址">
                        <el-input class="live-broadcast_code-url" disabled v-model="playerurl">
                            <template slot="suffix">
                                <button @click="copy(playerurl)" v-if="playerurl" class="clipboard-button">
                                    <copy class="live-broadcast_copy-icon" theme="outline" size="18" fill="#666"
                                        strokeLinecap="butt" />
                                </button>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="12">
                    <el-form-item label="预设位">
                        <el-button-group>
                            <el-button type="primary">
                                <file-addition-icon style="width: 10px;height: 10px;transform: scale(1.5);"
                                    color="#fff" />
                            </el-button>
                            <el-button type="primary">添加预设位</el-button>
                        </el-button-group>
                    </el-form-item>
                </el-col> -->
            </el-row>
        </el-form>
        <!-- <table-tool :tableData="tableData" /> -->
    </div>
</template>