/**
 * @author lixidong
 * @description 路由
 */
import AdminLTE from '@/components/AdminLTE/index.vue'
import Monitorin from '@/views/Monitorin/index.vue'
import DeviceMangement from '@/views/DeviceMangement.vue'
import UserMangement from '@/views/UserMangement/index.vue'
import EdgeConfiguration from '@/views/EdgeConfiguration/index.vue'
import BasisSetting from '@/views/BasisSetting/index.vue'
import  Home from '@/views/Home/index.vue'
import store from '@/store'
const routes = [
  {
    path: '/',
    name: 'monitorin',
    component: Monitorin,
    meta: { keepAlive: true, auth: true, title: '监控中心', icon: 'monitor' },
  },
  {
    path: '/device',
    name: 'device',
    component: AdminLTE,
    meta: { title: '设备中心', auth: true, icon: 'hard-drive' },
    redirect: { name: 'device_index' },
    children: [
      {
        path: '/device',
        name: 'device_index',
        meta: { auth: true, title: '设备管理' },
        component: DeviceMangement,
      },
      {
        path: '/device/group_management',
        name: 'group_management',
        meta: { auth: true, title: '分组管理' },
        component: () => import('@/views/GroupMangement.vue'),
      },
    ],
  },
  {
    path: '/device/channel_mangement',
    name: 'channel_mangement',
    meta: {
      auth: true,
      title: '通道列表',
      genealogy: ['device', 'device_index'],
      breadcrumb(meta, $route) {
        return `${meta.title}【 ${$route.query.DeviceName} 】`
      },
    },
    component: () => import('@/views/ChannelMangement.vue'),
  },
  {
    path: '/user_center',
    name: 'user_center',
    component: AdminLTE,
    meta: { auth: true, keepAlive: true, title: '用户中心' },
    redirect: { name: 'user_mangement' },
    children: [
      {
        path: '/user_center',
        name: 'user_mangement',
        meta: { auth: true, keepAlive: true, title: '用户管理' },
        component: UserMangement,
      },
      {
        path: '/user_center/role_mangement',
        name: 'role_mangement',
        meta: { auth: true, title: '角色管理' },
        component: () => import('@/views/RoleMangement.vue'),
      },
      {
        path: '/user_center/auth_mangement',
        name: 'auth_mangement',
        meta: { auth: true, title: '权限管理' },
        component: () => import('@/views/AuthMangement/index.vue'),
      },
      {
        path: '/user_center/menu_mangement',
        name: 'menu_mangement',
        meta: { auth: true, title: '菜单管理' },
        component: () => import('@/views/MenuMangement/index.vue'),
      },
    ],
  },
  {
    path: '/user_center/group_user',
    name: 'group_user',
    meta: {
      auth: true,
      title: '组用户',
      genealogy: ['user_center', 'user_mangement'],
      breadcrumb(meta, $route) {
        return `${$route.query.groupName || route.title}${
          $route.query.RoleName ? '[' + $route.query.RoleName + ']' : ''
        }`
      },
    },
    component: () => import('@/views/GroupUser/index.vue'),
  },
  {
    path: '/warning',
    name: 'warning',
    component: AdminLTE,
    meta: { auth: true, title: '预警中心' },
    redirect: { name: 'edge_configueration' },
    children: [
      {
        path: '/warning/warning_config',
        name: 'warning_config',
        meta: { auth: true, title: '告警配置' },
        component: () => import('@/views/WarningConfig.vue'),
      },
      {
        path: '/warning/warning_list',
        name: 'warning_list',
        meta: { auth: true, title: '告警列表' },
        component: () => import('@/views/WarningList/index.vue'),
      },
    ],
  },
  {
    path: '/warning/edge_configueration',
    name: 'edge_configueration',
    meta: { auth: true, title: '边缘配置', genealogy: ['warning'] },
    component: EdgeConfiguration,
  },
  {
    path: '/server',
    name: 'server',
    component: AdminLTE,
    meta: { auth: true, title: '系统配置' },
    redirect: { name: 'server_index' },
    children: [
      {
        path: '/server',
        name: 'server_index',
        component: BasisSetting,
        meta: { auth: true, title: '基础配置' },
      },
      {
        path: '/server/video_recording',
        name: 'video_recording',
        meta: { auth: true, title: '录像储存' },
        component: () => import('@/views/VideoRecording.vue'),
      },
      {
        path: '/server/operation_management',
        name: 'operation_management',
        meta: { auth: true, title: '运维管理' },
        component: () => import('@/views/OperationManagement.vue'),
      },
    ],
  },
  {
    path: '/server/black_and_white_list/:type',
    name: 'black_and_white_list',
    meta: {
      auth: true,
      title: '黑白名单',
      genealogy: ['server', 'server_index'],
      breadcrumb(meta, $route) {
        return `${$route.params.type === 'w' ? '白名单' : '黑名单'}`
      },
    },
    component: () => import('@/views/BlackAndWhiteList.vue'),
  },
  {
    path: '/home',
    name: 'home',
    meta: { auth: true, title: '首页' },
    component: Home,
  },
  {
    path: '/house',
    name: 'house',
    meta: { auth: true, title: '首页2' },
    component: () => import('@/views/House/index.vue'),
  },
  {
    path: '/nopermission',
    name: 'nopermission',
    meta: { title: '无权限', nologin: true },
    component: () => import('@/views/NoPermission.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { title: '退出', nologin: true },
    async beforeEnter(to, from, next) {
      await store.dispatch('logout')
      window.location.href = `/login.html`
    },
  },
  {
    path: '/dirct',
    name: 'dirct',
    meta: {
      title: '播放',
      nologin: true,
    },
    beforeEnter(to, from, next) {
      window.location.href = '/dirct.html'
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]

export default routes
