<script>
/***
 * @author lixidong
 * @description 通用模板
 */
import commonMixin from './utils/common.js'
import NSider from '../NSider.vue'
export default {
  mixins: [commonMixin],
  components: {
    NSider,
  },
}
</script>
<template>
  <div class="wrapper">
    <ChangePwd ref="change-pwd" />
    <NaviBar
      @dialogFormShow="() => $refs['change-pwd'].dialogFormShow()"
      :logoText="logoText"
      :logoMiniText="logoMiniText"
      :versionText="versionText"
    ></NaviBar>
    <!-- 最左侧导航栏 -->
    <NSider v-if="!!listNaviate" />
    <div
      id="adlte-main"
      class="run-ani_main content-wrapper active"
      :class="{ 'no-sider': !listNaviate }"
    >
      <section style="height: 100%" class="content">
        <!-- 面包屑 -->
        <BreadCrumb v-if="mode" />
        <!-- 内容区 -->
        <div class="content-wrapper_page">
          <keep-alive :max="10">
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </div>
      </section>
    </div>
    <footer
      class="run-ani_footer main-footer active"
      :class="{ 'no-sider': !listNaviate }"
    >
      <div class="pull-right hidden-xs hide">EasyDarwin</div>
      <strong
        >Copyright &copy; {{ Year() }}
        <a href="http://www.aihaohong.com">www.aihaohong.com</a>.</strong
      >
      All rights reserved.
    </footer>
  </div>
</template>

<style lang="less" scoped>
// 定义主视图区域
.content-wrapper_page {
  overflow: hidden;
  overflow-y: auto;
}

.content-wrapper,
.right-side,
.main-footer {
  transition: margin 0.3s linear;
}

//
.content-wrapper,
.main-footer {
  margin-left: 100px;
}

.content-wrapper.no-sider,
.main-footer.no-sider {
  margin-left: 0px;
}
</style>
<style scoped lang="less">
.content-wrapper_page {
  > div {
    width: 100%;
    height: 100%;

    /deep/.el-card {
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      .el-card__body {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        > .el-tabs {
          height: 100%;
        }
      }
    }
  }
}
</style>
<style lang="less">
.vue-back-to-top {
  background-color: transparent;
  left: 30px;
  bottom: 10px;
}

.sidebar-collapse .vue-back-to-top {
  display: none;
}
</style>
