<script>
/**
 * @author lixidong
 * @description 左侧导航栏
 */
import { mapGetters, mapState } from 'vuex'
export default {
  props: {
    mode: { type: Boolean, default: false }, // false 使用menu true 自定义
    list: { type: Array, default: () => [] },
    listActive: { type: Number, default: 0 },
  },

  data() {
    return {
      // 左侧边栏导航
      lactive: 0,
    }
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapGetters('menu', ['listNaviate']),
    ...mapState('menu', ['active']),
    path() {
      return location.pathname
    },
    activeList() {
      if (this.mode) return this.list
      return this.listNaviate
    },
    activeIndex() {
      if (this.mode) return this.listActive
      if (this.listNaviate && this.active) {
        const index = this.listNaviate.findIndex(
          (menu) => menu.EName === this.active[1].name
        )
        return index
      }
      return 0
    },
  },
  methods: {
    //  左侧导航栏点击跳转事件
    nslHandler(index, item) {
      if (this.mode) {
        this.$emit('clickHanlde', index, item)
        return false
      }
      this.$router.push({ name: item.EName })
    },
    // 背景动画
    bgAnimate(active) {
      this.$nextTick(() => {
        if (this.$refs.nslbg)
          this.$refs.nslbg.style.top = `${active * 40 + 5}px`
      })
    },
  },

  watch: {
    activeIndex: {
      handler(value) {
        this.bgAnimate(value)
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <aside class="n-sider">
    <section class="n-sider-left">
      <ul class="nsl-ul">
        <li
          v-for="(item, index) in activeList"
          :key="index"
          class="nsl-li"
          :class="{ 'nsl-active': index == activeIndex }"
          @click="nslHandler(index, item)"
        >
          <a>{{ mode ? item.title : item.CName }}</a>
        </li>
      </ul>
      <div v-if="activeIndex !== null" ref="nslbg" class="nsl-bg"></div>
    </section>
    <section class="n-sider-right">
      <slot />
    </section>
  </aside>
</template>
<style lang="less">
.n-sider {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  z-index: 810;
  display: flex;
  transition: width 0.3s linear;

  .n-sider-left {
    width: 100px;
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;

    .nsl-ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 2;

      .nsl-li {
        width: 100%;
        padding: 10px;
        text-align: center;
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }

  .n-sider-right {
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    width: 0px;
    transition: width 0.3s linear;
  }

  .nsl-bg {
    position: absolute;
    left: 10px;
    top: 5px;
    height: 30px;
    z-index: 1;
    width: calc(100% - 20px);
    border-radius: 0.3em;
    transition: top 0.3s ease;
  }
}

@media screen and (max-width: 767px) {
  .n-sider {
    padding-top: 100px;
  }
}
</style>
