<script>
/**
 * @author lixidong 
 * @description 标签页
 * @params {Array} value {lable,title}
 */
import gsap, { Power2 } from 'gsap';
import bus from './bus';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    active: Number / String,
  },
  methods: {
    // 选中事件
    handler (key) {
      this.$emit("update:active", key);
      this.$emit("handler", key, this.value.find(item => item.id == key))
    },
    // 操作逻辑
    do () {
      bus.$emit('update_active', this.active);
      this.animationFn();
    },
    // 解决父元素宽度动画带来的偏移问题140  解决方案不太友好
    // 使用监听动画元素的动画事件，但效果并不理想。
    offsetbug () {
      let i = 0;
      this.timer = setInterval(() => {
        if (i > 10) return clearInterval(this.timer)
        this.do();
        i++;
      }, 300)
    },

    // 动画逻辑 
    animationFn () {
      // 导航
      const items = $(this.$refs.tab_header).children('.tab_header-item');
      const indicator = $(this.$refs.tab_header).children('.tab_header-indicator');
      let active_key = this.value.findIndex(item => item.id == this.active)
      const { offsetLeft, offsetWidth } = items[active_key];
      const width = $(items[active_key]).width();
      gsap.to(indicator, { width: width, x: offsetLeft + (offsetWidth - width) / 2, duration: 0.3, ease: 'power2.out' })
      // 内容区 
      gsap.to(this.$refs.tab_content, {
        x: active_key * -100 + '%',
        duration: 0.3, ease: Power2.easeInOut
      })
    },
  },
  mounted () {
    window.addEventListener('resize', this.animationFn)
    this.offsetbug();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.animationFn)
    this.timer && clearInterval(this.timer)
  },
  watch: {
    active: {
      handler (newVal) {
        this.do();
      },
    }
  }
};
</script>

<template>
  <div class="tab">
    <!-- 顶部导航 -->
    <div ref="tab_header" class="tab_header">
      <div @click="handler(item.id)" v-for="(item) in value" :key="item.id" class="tab_header-item"
        :class="{ 'tab_header-active': item.id == active }">
        {{ item.label }}
      </div>
      <div class="tab_header-indicator"></div>
    </div>
    <!-- 内容区 -->
    <div class="tab_content" ref="tab_content">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="less" scoped>
.tab {
  width: 100%;
  overflow: hidden;

  .tab_header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 30px;
    margin-bottom: 20px;

    .tab_header-item {
      // width: 100px;
      padding: 15px 20px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .tab_header-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      transition: transform 0.3 ease;
    }

  }

  .tab_content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    will-change: transform;
    transform: translateX(0%);
  }
}
</style>