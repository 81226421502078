<script>
/**
 * @author lixidong
 * @description 信令服务配置
 * @link https://gbs.liveqing.com:10010/#/config
 */
import * as configurationApi from '@/api/configuration_api'
import { transformJson } from '@/utils/tool.js'
export default {
    data() {
        return {
            showGloab: true,//显示全部配置
            form: {
                sip_id: '',
                sip_host: '',
                sip_region: '',
                sip_port: '',
                unified_device_access_pwd: '',
                preferred_protocol: '',
                https_port: '',
                https_cert: '',
                https_key: '',
                national_secrets: false,
                other_configuration: [],
                timing_source: '',
                blacklist_ip: '',
                blacklist_ua: '',
                filter_channel_type: '',
                default_stream_mode: '',
                default_stream_mode_action: 1,
                subscriber_object: [],
                channel_audio: false,
                channel_share: false,
                map_enable: false,
                map_center: '',
                map_url: '',
                gb28181_lid: '',
                gb28181_region: '',
                gb28181_did: '',
                gb28181_cid: '',
                gb28181_dnum: '',
                gb28181_cnum: ''
            },
            rules: {

            },
            protocols: [
                { label: 'WS-FLV', value: 'ws-flv' },
                { label: 'HTTP-FLV', value: 'http-flv' },
                { label: 'HLS', value: 'hls' },
                { label: 'WebRTC', value: 'webrtc' }
            ],
            channel_types: [
                { value: 134, label: '报警输入' },
                { value: 135, label: '报警输入' },
                { value: 136, label: '语音输入' },
                { value: 137, label: '语音输出' },
                { value: 200, label: '中心信令' },
                { value: 215, label: '业务分组' },
                { value: 216, label: '虚拟组织' }
            ],
            submitLock: false
        }
    },
    computed: {
        span() {
            return this.showGloab ? 12 : 24
        }
    },
    created() {
        this.getConfiguration();
    },
    methods: {
        // 获取播放配置数据
        async getConfiguration() {
            const res = await configurationApi.signalling_configuration();
            if (res.code === 200) {
                var data = transformJson(res.data.Data, { ID: () => ('id') });
                if (Object.keys(data).length === 0) return false;
                this.form = data;
            }
        },
        // 提交
        submit() {
            this.$refs['form'].validate(async vaild => {
                if (vaild) {
                    try {
                        this.submitLock = true;
                        const res = await configurationApi.update_signalling_configuration(this.form);
                        if (res.code === 200) {
                            this.$message.success('保存成功');
                            this.getConfiguration();
                        }
                    } finally {
                        this.submitLock = false
                    }
                }
            })
        },
        // 地图上传
        async mapUploadHandle(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            formData.append('type', 'map');
            let res = await configurationApi.uploadFile(formData);
            if (res.code === 200) {
                this.$set(this.form, 'map_url', res.data);
                this.$message.success("上传成功")
            }
        },
        // 触发input file
        uploadHandle(ref) {
            this.$refs[ref] && this.$refs[ref].click()
        },
        async httpsCertFile(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'pem');
            let res = await configurationApi.uploadFile(formData);
            if (res.code === 200) {
                this.$set(this.form, 'https_cert', res.data);
                this.$message.success("上传成功")
            }
        },
        async httpsKeyFile(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'pem');
            let res = await configurationApi.uploadFile(formData);
            if (res.code === 200) {
                console.log(res.data)
                this.$set(this.form, 'https_key', res.data);
                this.$message.success("上传成功")
            }
        },
    }
}
</script>
<template>
    <div class="signling-services">
        <auth-wrapper slot auth="update-signling-services">
            <template v-slot="{ root }">
                <el-form :disabled="!root" ref="form" :model="form" :rules="rules" size="mini" label-width="160px">
                    <el-row :gutter="20">
                        <!-- <el-col :span="span">
                            <el-form-item label="SIP ID" prop="sip_id">
                                <el-input v-model="form.sip_id" placeholder="请输入SIP ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="SIP 域" prop="sip_region">
                                <el-input v-model="form.sip_region" placeholder="请输入SIP 域"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="SIP Host" prop="sip_host">
                                <el-input v-model="form.sip_host" placeholder="请输入SIP Host"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="SIP 端口" prop="sip_port">
                                <el-input v-model="form.sip_port" placeholder="请输入SIP 端口"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="设备统一接入密码" prop="unified_device_access_pwd">
                                <el-input v-model="form.unified_device_access_pwd" type="password"
                                    placeholder="请输入设备统一接入密码"></el-input>
                            </el-form-item></el-col>
                        <el-col :span="span">
                            <el-form-item label="首选直播格式" prop="preferred_protocol">
                                <el-select v-model="form.preferred_protocol" placeholder="请输入首选直播格式">
                                    <el-option v-for="item in protocols" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span" prop="https_port">
                            <el-form-item label="HTTPS 端口(可选)">
                                <el-input v-model="form.https_port" placeholder="请输入HTTPS 端口"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="HTTPS Cert 证书路径" prop="https_cert">
                                <el-input v-model="form.https_cert" placeholder="请输入HTTPS Cert 证书路径">
                                    <el-button class="signling-services_upload" @click="uploadHandle('https_cert')"
                                        icon="el-icon-upload" slot="append">
                                        <input v-input-upload="httpsCertFile" accept=".pem" type="file" ref="https_cert"
                                            style="display: none;">
                                    </el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="HTTPS Key 证书路径" prop="https_key">
                                <el-input v-model="form.https_key" placeholder="请输入HTTPS Key 证书路径">
                                    <el-button class="signling-services_upload" @click="uploadHandle('https_key')"
                                        icon="el-icon-upload" slot="append">
                                        <input v-input-upload="httpsKeyFile" accept=".pem" type="file" ref="https_key"
                                            style="display: none;"></el-button>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="接入控制(可选)">
                                <el-link @click="() => $router.push('/server/black_and_white_list/b')"
                                    :underline="false"><i style="margin-right: 5px;"
                                        class="fa fa-calendar-times-o"></i>黑名单</el-link>
                                <el-link @click="() => $router.push('/server/black_and_white_list/w')"
                                    :underline="false" style="margin-left: 8px"><i style="margin-right: 5px;"
                                        class="fa fa-calendar-check-o"></i>白名单</el-link>
                            </el-form-item>
                        </el-col>
         
                        <el-col :span="span">
                            <el-form-item label="其他配置" prop="other_configuration">
                                <el-checkbox-group v-model="form.other_configuration">
                                    <el-checkbox label="http_interface">HTTP接口鉴权</el-checkbox>
                                    <el-checkbox label="stream_url">流地址鉴权</el-checkbox>
                                    <el-checkbox label="allow_pulling_flow">允许直播地址拉流</el-checkbox>
                                    <el-checkbox label="signaling_log">信令日志</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="校时源(可选)" prop="timing_source">
                                <el-input v-model="form.timing_source" placeholder="请输入校时源"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="黑名单 IP(可选)" prop="blacklist_ip">
                                <el-input v-model="form.blacklist_ip" placeholder="请输入黑名单 IP"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="黑名单 UA(可选)" prop="blacklist_ua">
                                <el-input v-model="form.blacklist_ua" placeholder="请输入黑名单 UA"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="全局过滤通道类型" prop="filter_channel_type">
                                <el-select v-model="form.filter_channel_type" placeholder="选填，需要丢弃的通道类型">
                                    <el-option v-for="item in channel_types" :key="item.value"
                                        :label="item.value + ' - ' + item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="默认流传输模式" prop="default_stream_mode">
                                <el-radio-group v-model="form.default_stream_mode">
                                    <el-radio-button label="TCP"></el-radio-button>
                                    <el-radio-button label="UDP"></el-radio-button>
                                </el-radio-group>
                                <el-radio-group v-if="form.default_stream_mode == 'TCP'" style="margin-left: 8px"
                                    v-model="form.default_stream_mode_action">
                                    <el-radio-button label="0">被动</el-radio-button>
                                    <el-radio-button label="1">主动</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="全局订阅项目" prop="subscriber_object">
                                <el-checkbox-group v-model="form.subscriber_object">
                                    <el-checkbox label="catalog">目录订阅</el-checkbox>
                                    <el-checkbox label="alarm">报警订阅</el-checkbox>
                                    <el-checkbox label="address">位置订阅</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="全局通道开启音频" prop="channel_audio">
                                <el-switch v-model="form.channel_audio"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="全局通道开启分享" prop="channel_share">
                                <el-switch v-model="form.channel_share"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span" prop="map_enable">
                            <el-form-item label="电子地图">
                                <el-switch v-model="form.map_enable"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab && form.map_enable" :span="span" prop="map_center">
                            <el-form-item label="地图中心(经,纬度)">
                                <el-input v-model="form.map_center" placeholder="请输入地图中心(经,纬度)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab && form.map_enable" :span="span">
                            <el-form-item label="地图包上传(zip)">
                                <el-upload accept="application/zip" class="upload-demo"
                                    :class="{ 'active': !!form.map_url }" :show-file-list="false" drag action=""
                                    :http-request="mapUploadHandle"> <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将地图包(zip)拖到此处，或<em>点击上传</em></div>
                                </el-upload>
                            </el-form-item>
                        </el-col> -->
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181系统ID" prop="gb28181_lid">
                                <el-input v-model="form.gb28181_lid" placeholder="请输入GB28181系统ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181系统域" prop="gb28181_region">
                                <el-input v-model="form.gb28181_region" placeholder="请输入GB28181系统域"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181设备前缀" prop="gb28181_did">
                                <el-input v-model="form.gb28181_did" placeholder="请输入GB28181设备前缀"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181通道前缀" prop="gb28181_cid">
                                <el-input v-model="form.gb28181_cid" placeholder="请输入GB28181通道前缀"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181设备ID" prop="gb28181_dnum">
                                <el-input v-model="form.gb28181_dnum" placeholder="请输入GB28181设备ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="GB28181通道ID" prop="gb28181_cnum">
                                <el-input v-model="form.gb28181_cnum" placeholder="请输入GB28181通道ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-checkbox v-model="showGloab">显示更多配置</el-checkbox>
                                    </el-col>
                                    <auth-wrapper auth="update-signling-services">
                                        <el-col :span="12">
                                            <el-button @click="submit" :loading="submitLock" :disabled="submitLock"
                                                type="primary">保存</el-button>
                                        </el-col>
                                    </auth-wrapper>
                                </el-row>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </template>
        </auth-wrapper>
    </div>
</template>

<style lang="less" scoped>
.signling-services {
    .el-icon-upload {
        font-size: 60px;
        margin: 30px 0 0px;
        line-height: 30px
    }

    .el-upload__text {
        font-size: 12px;
    }

    .signling-services_upload {
        width: 12px;
    }

    /deep/ .el-upload-dragger {
        width: 240px;
        height: 120px;
    }

    .upload-demo {
        width: 240px;
        height: 120px;
        box-sizing: border-box
    }

    .upload-demo.active {
        /deep/ .el-icon-upload {
            color: var(--color-green)
        }
    }
}
</style>

               <!-- <el-col :span="span">
                    <el-form-item label="35114 国密认证">
                        <el-checkbox v-model="form.national_secrets">启动国密</el-checkbox>
                        <template v-if="form.national_secrets">
                            <el-link :underline="false" style="margin-right: 8px;margin-left:8px;line-height: 19px;"><i
                                    style="margin-right: 5px;" class="fa fa fa-upload"></i>上传CA证书</el-link>
                            <el-link :underline="false" style="line-height: 19px;margin-right: 8px"><i
                                    style="margin-right: 5px;" class="fa fa-certificate"></i>创建证书请求</el-link>
                            <el-link :underline="false" style="line-height: 19px;margin-right: 8px"><i
                                    style="margin-right: 5px;" class="fa fa-edit"></i>签发设备证书</el-link>
                        </template>
</el-form-item>
</el-col> -->