<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
export default {
  data() {
    return {
      value: [
        { name: '于浩敏', datetime: '2024-06-25 18:30:00', action: '进入' },
        { name: '刘宗', datetime: '2024-06-25 18:25:00', action: '外出' },
        { name: '张振', datetime: '2024-06-25 18:24:00', action: '外出' },
        { name: '于浩敏', datetime: '2024-06-25 18:15:00', action: '外出' },
        { name: '朱志军', datetime: '2024-06-25 18:10:00', action: '进入' },
        { name: '覃田', datetime: '2024-06-25 17:50:00', action: '外出' },
        { name: '刘宽宽', datetime: '2024-06-25 17:46:00', action: '外出' },
        { name: '李军', datetime: '2024-06-25 17:30:00', action: '进入' },
        { name: '李强', datetime: '2024-06-25 17:22:00', action: '进入' },
        { name: '朱志军', datetime: '2024-06-25 17:10:00', action: '外出' },
      ],
    }
  },
  methods: {
    // 补位
    strCover(val, step = 3) {
      val = String(val)
      if (val.length < step) {
        const stepVal = step - val.length
        for (let i = 0; i < stepVal; i++) {
          val = '0' + val // 在每次循环中前面加 '0'
        }
      }
      return val
    },
  },
}
</script>

<template>
  <div class="threeD-popule">
    <div class="threeD-detail-header">
      <div>人员信息</div>
      <div>详情</div>
    </div>
    <div class="threeD-detail-main">
      <el-row
        v-for="(item, index) in value"
        :key="index"
        type="flex"
        align="middle"
        :gutter="10"
      >
        <el-col class="ellipsis" :span="4">{{ strCover(index + 1) }}</el-col>
        <el-col class="ellipsis" :span="6">{{ item.name }}</el-col>
        <el-col style="color: var(--color-white)" class="ellipsis" :span="10">{{
          item.datetime
        }}</el-col>
        <el-col class="ellipsis" :span="4"
          ><span style="color: var(--color-red)" :role="item.action">{{
            item.action
          }}</span></el-col
        >
      </el-row>
    </div>
  </div>
</template>
