<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
export default {
  data() {
    return {}
  },
}
</script>

<template>
  <div class="threeD-warm">
    <div class="threeD-detail-header">
      <div>报警信息</div>
      <div>详情</div>
    </div>
    <div class="threeD-detail-main">
      <el-row style="height: 100%">
        <el-col
          style="
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
          :span="12"
        >
          <div>今日报警数</div>
          <div
            style="
              font-size: 18px;
              color: var(--color-white);
              font-weight: bold;
              line-height: 36px;
            "
          >
           30
          </div>
        </el-col>
        <el-col
          style="
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          "
          :span="12"
        >
          <div>近30日平均报警</div>
          <div
            style="
              font-size: 18px;
              color: var(--color-white);
              font-weight: bold;
              line-height: 36px;
            "
          >
            24
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
