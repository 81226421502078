<script>
/**
 * @author lixidong
 * @description 流媒体服务配置
 */
import * as configurationApi from '@/api/configuration_api'
import { transformJson,trimmedForm } from '@/utils/tool.js'
export default {
    data() {
        return {
            showGloab: true,//显示全部配置
            form: {
                zrm_server: '',
                external_ip: '',
                local_ip: '',
                clud_recording_directory: '',
                rtsp_port: '',
                other_configuration: [],
                zrm_id: '',
                zrm_region: '',
                zrm_port: '',
                group_id: '',
                record_retention_days: 7,
                storage_cleanup_threshold: 5,
                storage_cleanup_threshold_mb: 5120,
                receiving_port_interval_tcp: '',
                receiving_port_interval_udp: '',
                webrtc_port_interval_udp: '',
                media_restful: '',
                media_http: '',
                media_ws: '',
                media_rtmp: '',
                media_rtsp: '',
                media_rtp:'',
                media_secret: '',
                stream_hls: '',
                stream_rtmp: ''
            },
            rules: {},
            submitLock: false,
        }
    },
    computed: {
        span() {
            return this.showGloab ? 12 : 24
        }
    },
    created() {
        this.getConfiguration();
    },
    methods: {
        // 获取播放配置数据
        async getConfiguration() {
            const res = await configurationApi.streaming_configuration();
            if (res.code === 200) {
                var data = transformJson(res.data.Data, { ID: () => ('id') });
                if (Object.keys(data).length === 0) return false;
                this.form = data;
            }
        },
        // 提交
        submit() {
            this.$refs['form'].validate(async vaild => {
                if (vaild) {
                    try {
                        this.submitLock = true;
                        const res = await configurationApi.update_streaming_configuration(trimmedForm(this.form));
                        if (res.code === 200) {
                            this.$message.success('保存成功');
                            this.getConfiguration();
                        }
                    } finally {
                        this.submitLock = false
                    }
                }
            })
        },
    }
}
</script>
<template>
    <div class="streaming-services">
        <auth-wrapper slot auth="update-streaming-services">
            <template v-slot="{ root }">
                <el-form :disabled="!root" ref="form" :model="form" :rules="rules" size="mini" label-width="200px">
                    <el-row :gutter="20">
                        <!-- <el-col :span="span">
                            <el-form-item label="ZRM 服务" prop="zrm_server">
                                <el-select v-model="form.zrm_server" placeholder="请输入ZRM 服务"></el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="本地｜内网 IP" prop="local_ip">
                                <el-input v-model="form.local_ip" placeholder="请输入本地｜内网 IP"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="外网IP(可选)" prop="external_ip">
                                <el-input v-model="form.external_ip" placeholder="请输入外网IP"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="云录像目录" prop="clud_recording_directory">
                                <el-input v-model="form.clud_recording_directory" placeholder="请输入云录像目录"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="span">
                            <el-form-item label="RTSP端口(可选)" prop="rtsp_port">
                                <el-input v-model="form.rtsp_port" placeholder="请输入RTSP端口"></el-input>
                            </el-form-item></el-col>
                        <el-col :span="span">
                            <el-form-item label="其他配置" prop="other_configuration">
                                <el-checkbox-group v-model="form.other_configuration">
                                    <el-checkbox label="live_streaming_on_in_seconds">直播秒开</el-checkbox>
                                    <el-checkbox label="signaling_log">信令日志</el-checkbox>
                                    <el-checkbox label="external_ip_traffic_collection">外网IP收流</el-checkbox>
                                    <el-checkbox label="H265_FLV">H265_FLV</el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="ZRM ID" prop="zrm_id">
                                <el-input v-model="form.zrm_id" placeholder="请输入ZRM ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="ZRM 域" prop="zrm_region">
                                <el-input v-model="form.zrm_region" placeholder="请输入ZRM 域"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="ZRM 端口" prop="zrm_port">
                                <el-input v-model="form.zrm_port" placeholder="请输入ZRM 端口"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="分组 ID(可选)" prop="group_id">
                                <el-input v-model="form.group_id" placeholder="请输入分组 ID"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="录像保留(天数)" prop="record_retention_days">
                                <el-input-number :min="0" v-model="form.record_retention_days"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="存储清理阀值(%)" prop="storage_cleanup_threshold">
                                <el-input-number :min="0" v-model="form.storage_cleanup_threshold"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="存储清理阀值(MB)" prop="storage_cleanup_threshold_mb">
                                <el-input-number :min="0" v-model="form.storage_cleanup_threshold_mb"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="收流端口区间(TCP)" prop="receiving_port_interval_tcp">
                                <el-input v-model="form.receiving_port_interval_tcp"
                                    placeholder="请输入收流端口区间(TCP)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="收流端口区间(UDP)" prop="receiving_port_interval_udp">
                                <el-input v-model="form.receiving_port_interval_udp"
                                    placeholder="请输入收流端口区间(UDP)"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="WebRTC端口区间(UDP)" prop="webrtc_port_interval_udp">
                                <el-input v-model="form.webrtc_port_interval_udp"
                                    placeholder="请输入WebRTC端口区间(UDP)"></el-input>
                            </el-form-item>
                        </el-col> -->
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="RESTFUL请求地址" prop="media_restful">
                                <el-input v-model="form.media_restful" placeholder="请输入RESTFUL请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="HTTP请求地址" prop="media_http">
                                <el-input v-model="form.media_http" placeholder="请输入HTTP请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="WS请求地址" prop="media_ws">
                                <el-input v-model="form.media_ws" placeholder="请输入WS请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="RTMP请求地址" prop="media_rtmp">
                                <el-input v-model="form.media_rtmp" placeholder="请输入RTMP请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="RTSP请求地址" prop="media_rtsp">
                                <el-input v-model="form.media_rtsp" placeholder="请输入RTSP请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="RTP请求地址" prop="media_rtp">
                                <el-input v-model="form.media_rtp" placeholder="请输入RTP请求地址"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="接口验证" prop="media_secret">
                                <el-input v-model="form.media_secret" placeholder="请输入RTP接口验证"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="开启视频转hls" prop="stream_hls">
                                <el-switch v-model="form.stream_hls"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col v-if="showGloab" :span="span">
                            <el-form-item label="开启视频转rtmp" prop="stream_rtmp">
                                <el-switch v-model="form.stream_rtmp"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item>
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-checkbox  v-model="showGloab">显示更多配置</el-checkbox>
                                    </el-col>
                                    <auth-wrapper  auth="update-streaming-services">
                                    <el-col :span="12">
                                        <el-button @click="submit" :loading="submitLock" :disabled="submitLock"
                                            type="primary">保存</el-button>
                                    </el-col>
                                    </auth-wrapper>
                                </el-row>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </template>
        </auth-wrapper>
    </div>
</template>