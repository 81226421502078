/**
 * @author lixidong
 * @description  鉴权 函数式组件 仅负责渲染
 */
import store from '@/store';


// 判断是否拥有权限
function authStatus(auth, rootKeys) {
    return Array.isArray(auth) ? auth.every(key => rootKeys.includes(key)) : rootKeys.includes(auth);
}

export default {
    functional: true,//无状态
    props: {
        auth: [String, Array],
        slot: Boolean
    },
    render(h, { props, children, scopedSlots }) {
        const { rootKeys } = store.state.root;
        if (props.auth) {
            const hasAuth = authStatus(props.auth, rootKeys);
            if (props.slot && scopedSlots.default) {
                return scopedSlots.default({ root: hasAuth })
            } else {
                return hasAuth ? children : h('div', '')
            }
        } else {
            return h('div', '')
        }
    }
}