/**
 * @author lixidong
 * @description 项目配置文件
 */
import { ifspecification } from '@/utils/tool.js'
//  服务器访问路径
//  200 ok 400 报错  404 服务不存在  403 没有权限访问该资源
// 192.168.1.10:10008

export const SERVERURLS = [
  {
    transitionPath: '/easy_darwin_server/api/v1',
    transitionReg: /^\/easy_darwin_server\/api\/v1\/.*/,
    transitionTarget: {
      // development: 'http://192.168.1.14:5001',
      development: 'http://192.168.1.22:10008',
      production: 'http://192.168.1.14:5001',
    },
    transitionResponse(response) {
      const _response = { code: '', data: '' }
      let { Code, Data, Message } = response
      if (ifspecification(Code, 1)) {
        _response.code = 200
      } else {
        _response.code = 400
      }
      _response.data = Data ? JSON.parse(Data) : {}
      _response.message = Message || '服务器出错了！'
      return _response
    },
    transitionError(xhr, status, error) {
      if (xhr.status == 401) {
        window.location.href = '/'
        return false
      }
      let msg = xhr.responseText || '网络请求失败'
      if (xhr.status == 404) {
        msg = '请求服务不存在或已停止'
      }
      if (xhr.status == 400) {
        msg = xhr.responseJSON
          ? xhr.responseJSON.Message
          : xhr.responseText
          ? xhr.responseText
          : msg
      }

      return {
        type: 'error',
        message: msg,
      }
    },
  },
  {
    transitionPath: '/gosip_server/api/v1',
    transitionReg: /^\/gosip_server\/api\/v1\/.*/,
    transitionTarget: {
      // development: 'http://192.168.1.168:8090',
      // development: 'http://192.168.1.10:8090',·
      development: 'http://192.168.1.17:8090',
      production: 'http://192.168.1.168:8090',
    },
    transitionResponse(response) {
      const _response = { code: '', data: '' }
      let { code, data } = response
      if (ifspecification(code, 0)) {
        _response.code = 200
        _response.data = data
      } else {
        _response.code = 400
        _response.data = null
      }
      return _response
    },
    transitionError(xhr, status, error) {
      if (xhr.status == 401) {
        window.location.href = '/'
        return false
      }
      let msg = xhr.responseText || '网络请求失败'
      if (xhr.status == 404) {
        msg = '请求服务不存在或已停止'
      }
      if (xhr.status == 400) {
        msg = xhr.responseJSON
          ? xhr.responseJSON.data
          : xhr.responseText
          ? xhr.responseText
          : msg
      }

      return {
        type: 'error',
        message: msg,
      }
    },
  },
  {
    transitionPath: '/camera_api/api/v3',
    transitionReg: /^\/camera_api\/api\/v3\/.*/,
    transitionTarget: {
      // development: 'http://192.168.1.168:8090',
      // development: 'http://192.168.1.10:8090',·
      development: 'http://192.168.1.168:59750',
      production: 'http://192.168.1.168:8090',
    },
    transitionResponse(response) {
      const _response = { code: '', data: '' }
      let { Code, Data } = response
      if (ifspecification(Code, 0)) {
        _response.code = 200
        _response.data = Data
      } else {
        _response.code = 400
        _response.data = null
      }
      return _response
    },
    transitionError(xhr, status, error) {
      if (xhr.status == 401) {
        window.location.href = '/'
        return false
      }
      let msg = xhr.responseText || '网络请求失败'
      if (xhr.status == 404) {
        msg = '请求服务不存在或已停止'
      }
      if (xhr.status == 400) {
        msg = xhr.responseJSON
          ? xhr.responseJSON.data
          : xhr.responseText
          ? xhr.responseText
          : msg
      }

      return {
        type: 'error',
        message: msg,
      }
    },
  },
  {
    transitionPath: '/prometheus/api/v1',
    transitionReg: /^\/prometheus\/api\/v1\/.*/,
    transitionTarget: {
      // development: 'http://192.168.1.168:8090',
      // development: 'http://192.168.1.10:8090',·
      development: 'http://192.168.1.168:59750',
      production: 'http://192.168.1.168:8090',
    },
    transitionResponse(response) {
      const _response = { code: '', data: '' }
      _response.code = 200
      _response.data = response.data
      return _response
    },
    transitionError(xhr, status, error) {
      if (xhr.status == 401) {
        window.location.href = '/'
        return false
      }
      let msg = xhr.responseText || '网络请求失败'
      if (xhr.status == 404) {
        msg = '请求服务不存在或已停止'
      }
      if (xhr.status == 400) {
        msg = xhr.responseJSON
          ? xhr.responseJSON.data
          : xhr.responseText
          ? xhr.responseText
          : msg
      }

      return {
        type: 'error',
        message: msg,
      }
    },
  },
]

export const MQTT = {
  host: '192.168.123.198',
  port: 59001,
  // host:'localhost',
  // port:9001,
  // path: '/mqtt',
  topic: 'incoming/data/edge-video-analytics/inference-event',
}
