<script>
/**
 * @auhtor lixidong
 * @description 告警
 */
import Liveplayer from '@liveqing/liveplayer'
import * as mockApi from '@/mock/common/api.js'
export default {
  components: { Liveplayer },
  data() {
    return {
      videoUrl: '',
      loading: true,
      mockValue: null,
      imgSrc: '',
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    async reset() {
      let res = await mockApi.warningDetail()
      if (res.code === 200) {
        const videoData = res.data
        this.videoUrl = videoData.videoUrl
        this.imgSrc = videoData.imgSrc
        this.mockValue = videoData.mockValue
      }
    },
  },
}
</script>

<template>
  <div class="warm">
    <div class="warm-side">
      <div
        style="height: 100%; width: 100%; background-color: var(--color-black)"
        v-loading="loading"
        element-loading-background="rgba(0,0,0,1)"
        element-loading-text="视频拼命加载中"
      >
        <video
          @play="() => (loading = false)"
          style="height: 100%; width: 100%; object-fit: cover"
          :src="videoUrl"
          muted
          autoplay
          ref="video"
          controls
        >
          <source :src="videoUrl" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <canvas
          ref="canvas"
          style="display: none; height: 100%; width: 100%"
        ></canvas>
      </div>
    </div>
    <div class="warm-main">
      <div class="li-skeleton">
        <img v-if="imgSrc" :src="imgSrc" alt="" />
      </div>
      <div class="warm-info" :class="{ 'li-skeleton': !mockValue }">
        <template v-if="mockValue">
          <div>
            <div>
              <span>报</span><span>警</span><span>类</span><span>型：</span>
            </div>
            <div>抽烟异常</div>
          </div>
          <div>
            <div><span>地</span><span>点：</span></div>
            <div>{{ mockValue.Location }}</div>
          </div>
          <div>
            <div><span>时</span><span>间：</span></div>
            <div>{{ mockValue.Time }}</div>
          </div>
          <div>
            <div><span>负</span><span>责</span><span>人：</span></div>
            <div>
              <div v-for="(item, index) in mockValue.Responsibles" :key="index">
                <span
                  v-if="item.name == mockValue.AlertStatus"
                  style="color: var(--color-green)"
                  class="el-icon-circle-check"
                ></span>
                <span v-else class="warm-circle"></span>
                &nbsp;{{ item.name + ' ' + item.phone }}
              </div>
            </div>
          </div>
          <div>
            <div>
              <span>报</span><span>警</span><span>状</span><span>态：</span>
            </div>
            <div>
              {{ mockValue.AlertStatus }}
              <el-tag
                style="border-radius: 20px"
                effect="dark"
                size="small"
                type="primary"
                >已处理</el-tag
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
@height: 500px;

.warm {
  display: flex;

  .warm-side {
    height: @height;
    width: 60%;
    padding-right: 20px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .warm-main {
    width: 40%;

    > div:first-child {
      width: 100%;
      height: calc(@height / 2);
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    > div:last-child {
      width: 100%;
      height: calc(@height / 2 - 20px);
    }

    .warm-info {
      .warm-circle {
        width: 14px;
        height: 14px;
        transform: scale(0.9);
        border-radius: 50%;
        display: inline-block;
        border: 1px solid var(--color-grey);
        box-sizing: border-box;
      }

      > div {
        display: flex;

        > div:first-child {
          width: 72px;
          display: flex;
          justify-content: space-between;
          color: var(--color-grey);
        }

        > div:last-child {
          > div {
            display: flex;
            align-items: center;
          }
        }

        div {
          line-height: 30px;
        }
      }

      > div:first-child {
        color: var(--color-red);
        font-size: 24px;
        font-weight: bold;

        > div:first-child {
          color: var(--color-red);
          width: 120px;
        }
      }
    }
  }
}

.li-skeleton {
  background: linear-gradient(90deg, #f1f2f4 25%, #e6e6e6 37%, #f1f2f4 50%);
  background-size: 400% 100%;
  border-radius: 3px;
  animation: gradientAnimation 1.8s ease infinite;
  /* 添加动画效果 */
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 50%;
    /* 开始位置 */
  }

  100% {
    background-position: 0% 50%;
    /* 结束位置 */
  }
}
</style>
