export const warningDetail = () => {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/mock/warning/detail',
      method: 'GET',
      success: function (data) {
        if (typeof data == 'string') {
          data = JSON.parse(data)
        }
        resolve(data)
      },
      error: function (xhr, status, error) {
        reject(error)
      },
    })
  })
}
