<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import Warm from './index.vue'
export default {
  components: { Warm },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    showDialog() {
      this.$refs.warm && this.$refs.warm.reset()
      this.dialogVisible = true
    },
  },
}
</script>

<template>
  <el-dialog :visible.sync="dialogVisible" width="80%">
    <warm ref="warm" />
  </el-dialog>
</template>
