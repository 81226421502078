/**
 * @author lixidong
 * @description 表格
 */
import TableTool from '@/components/TableTool/index.vue'
import * as cameraApi from '@/api/camera_api'
import moment from 'moment'
import Warm from '@/components/Warm/dialog.vue'
import DialogPlayer from '@/components/DialogPlayer/index.vue'
export default {
  components: { TableTool, Warm, DialogPlayer },
  data() {
    return {
      // 表格数据
      tableData: {
        stripe: true,
        border: false,

        size: 'mini',
        reqIntercept: this.reqIntercept,
        repIntercept: (data) => {
          return data
        },
        columns: [
          {
            prop: 'AIBoxName',
            label: '设备名称',
            width: 120,
            slot: true,
          },
          {
            prop: 'CameraIP',
            label: '报警类型',
            width: 140,
            slot: true,
          },
          // {
          //   prop: 'CameraChannel',
          //   label: '通道名称',
          // },
          {
            prop: 'Snapshot',
            width: 150,
            slot: true,
            label: '关联快照',
          },
          // {
          //   prop: 'Video',
          //   label: '关联录像',
          //   width: 300,
          //   slot: true,
          // },
          // {
          //   prop: 'Warnlevel',
          //   label: '报警级别',
          // },
          // {
          //   prop: 'WarnMethod',
          //   label: '报警方式',
          // },
          {
            prop: 'a',
            width: 170,
            label: '负责人',
            slot: true,
          },
          // {
          //   prop: 'WarnType',
          //   label: '报警类型',
          // },
          {
            prop: 'b',
            label: '处理状态',
            width: 80,
            slot: true,
          },
          {
            prop: 'Timestamp',
            label: '报警时间',
            template: this.dateChange,
          },
        ],
        btns: [
          {
            label: '详情',
            slot: 'info',
            width: 70,
          },
        ],
      },
    }
  },
  methods: {
    // 获取详情
    infoChannel(scope) {
      this.$refs.warm.showDialog()
    },
    // 下载视频
    async download(url) {
      //根据url下载视频
      let res = await cameraApi.downloadVideo(url)
      const downloadUrl = window.URL.createObjectURL(res)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = downloadUrl
      a.download = url.split('/').pop() // 以视频文件名作为下载文件名
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(downloadUrl)
      document.body.removeChild(a)
    },
    // 时间转换
    dateChange(scope) {
      return moment(scope.row.CreateTime).format('YYYY-MM-DD HH:mm:ss')
      // return scope.row.Timestamp
    },
    // 赋值功能
    copy(value) {
      this.$copyText(value).then(
        (e) => this.$message.success('复制成功'),
        (e) => this.$message.error('复制失败')
      )
    },
    // 播放
    playVidoe(scope) {
      this.$refs['dialog-player'].showDialog(
        scope.row.video,
        scope.row.AIBoxName
      )
    },
    // 通道数据请求拦截
    async reqIntercept(query) {
      let res = await cameraApi.selectEvent({
        PageInfo: {
          PageIdx: query.currentPage,
          PageSize: query.pageSize,
        },
      })
      if (res.code === 200) {
        let { Total, WarningInfos } = res.data
        return { data: WarningInfos, total: Total }
      }
      return { data: [], total: 0 }
    },
  },
}
