/**
 * @author lixidong
 * @description 通道信息 设备信息
 * @link http://192.168.1.168:8090/swagger/index.html#/devices/get_devices
 */
import { get, post ,del} from './index';


// 设备

// 设备配置列表
export const device_setting_list = (data) => {
  return get("/gosip_server/api/v1/devices",data)
}

// 添加设备 
export const adddevice=(data)=>{
  return post("/gosip_server/api/v1/devices",data)
}

// 修改设备 
export const editdevice=data=>{
  return post(`/gosip_server/api/v1/devices/${data.deviceid}`,data)
}


// 删除设备 
export const deldevice=(data)=>{
  return del(`/gosip_server/api/v1/devices/${data.deviceid}`)
}

// 通道

// 通道列表 
export const channels_config=data=>{
  return get("/gosip_server/api/v1/channels",data)
}


// 通道添加 
export const add_channel=data=>{
  return post(`/gosip_server/api/v1/devices/${data.deviceid}/channels`,data)
}

// 修改通道
export const edit_channel=data=>{
  return post(`/gosip_server/api/v1/channels/${data.channelid}`,data)
}


// 通道删除 
export const del_channel=data=>{
  return del(`/gosip_server/api/v1/channels/${data.channelid}`)
}

