<script>
/**
 * @author lixidong
 */
import { mapActions } from 'vuex'
// import Warm from '@/components/Warm/dialog.vue'
export default {
  // components: {  Warm },
  created() {
    // 获取菜单配置
    this.getMenuConfig()
  },
  methods: {
    ...mapActions('menu', ['updateRoute', 'getMenuConfig']),
  },
  watch: {
    // 更新路由信息
    $route: {
      deep: true,
      immediate: true,
      handler(val) {
        this.updateRoute(val)
      },
    },
  },
}
</script>

<template>
  <div id="app">
    <keep-alive :max="10">
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<style lang="less" scoped>
#app {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
}
</style>
