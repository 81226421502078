<script>
/**
 * @author lixidong
 * @description 自定义宫格数
 * @example 1 4 9 16
 */
import { uuid as _uuid } from '@/utils/tool.js';

export default {
  props: {
    count: {
      type: Number,
      default: 9,
    },
  },
  data () {
    return {
      svg: {
        // rect左上角的坐标
        xyRect: 0,
        // rect 宽高
        whRect: 48,
        // path 的 路径
        xPath: [],
        yPath: [],
      },
    };
  },
  computed: {},
  methods: {
    // 生成唯一id
    uuid (key) {
      return _uuid(key)
    },
    // 根据count动态生成svg的path
    edit_path () {
      // svg 48 48
      let svgWH = 48;
      let chunk_number = Math.sqrt(this.count);
      if (!Number.isInteger(chunk_number))
        return console.error("count 必须是整数的平方");
      let line_number = chunk_number - 1;
      // Rect
      let xyRect = (svgWH % chunk_number) / 2;
      let whRect = 48 - xyRect * 2;
      // Path
      let xPath = [],
        yPath = [];
      for (let i = 0; i < line_number; i++) {
        // "M14 4V44"
        let setup = xyRect + (whRect / chunk_number) * (i + 1);
        let end = svgWH - xyRect;
        xPath.push(`M${setup} ${xyRect}V${end}`);
        yPath.push(`M${xyRect} ${setup}H${end}`);
      }
      this.svg = {
        // rect左上角的坐标
        xyRect,
        // rect 宽高
        whRect: svgWH - xyRect * 2,
        // path 的 路径
        xPath,
        yPath,
      };
    },
  },
  watch: {
    count: {
      handler (val) {
        this.edit_path();
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <svg class="svg-icon_lattice" width="18" height="18" @click="$emit('click')" viewBox="0 0 48 48" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect :x="svg.xyRect" :y="svg.xyRect" :width="svg.whRect" :height="svg.whRect" rx="10" ry="10" stroke="#fff"
      stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path v-for="(item, index) in svg.xPath" :key="uuid(index)" :d="item" stroke="#fff" stroke-width="4"
      stroke-linecap="round" />
    <path v-for="(item, index) in svg.yPath" :key="uuid(index)" :d="item" stroke="#fff" stroke-width="4"
      stroke-linecap="round" />
  </svg>
</template>

<style lang="less" scoped>
svg {
  cursor: pointer;
}
</style>
