<script>
/**
 * @auhtor lixidong
 * @description 首页
 */
import ListTe from './list.vue'
import CarTe from './car.vue'
import DayTe from './day.vue'
import PopuleTe from './popule.vue'
import WarmTe from './warm.vue'
import ServeTe from './serve.vue'
export default {
  components: { ListTe, CarTe, DayTe, PopuleTe, WarmTe, ServeTe },
}
</script>

<template>
  <div
    v-img-lazy:backgroundImage="require('@/assets/images/warm/group2-1x.png')"
    class="threeD"
  >
    <div class="threeD-main"></div>
    <ListTe />
    <ServeTe />
    <WarmTe />
    <DayTe />
    <PopuleTe />
    <CarTe />
  </div>
</template>

<style lang="less">
.threeD {
  .threeD-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > div:first-child {
      color: #fff;
    }
    > div:last-child {
      font-size: 14px;
      color: #636ab0;
    }
  }
  .threeD-detail-main {
    height: calc(100% - 35px);
    max-width: calc(100vw / 5 - 120px / 5 - 40px);
    color: #9b9ca6;
    overflow: hidden;
    overflow-y: auto;
  }
  .threeD-detail-main ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  .threeD-detail-main ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }

  .threeD-detail-main ::-webkit-scrollbar-track {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: transparent;
  }

  .threeD-detail-main :hover::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  .threeD-detail-main :hover::-webkit-scrollbar-thumb {
    background-color: @base;
  }

  .threeD-detail-main :hover::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
  @supports (scrollbar-width: thin) {
    div {
      scrollbar-width: none; //firefox浏览器
    }
    div:hover {
      scrollbar-width: thin;
      scrollbar-color: @base rgba(0, 0, 0, 0.1);
    }
  }
}
</style>

<style lang="less" scoped>
.threeD {
  width: 100%;
  height: 100%;
  display: grid; /* 确保这是网格容器 */
  grid-template-areas:
    'main main main main list'
    'main main main main list'
    'main main main main list'
    'serve warm day popule car';
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background: url('@/assets/images/warm/group2-1x.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 90%;
  grid-auto-rows: 1fr; /* 确保每行高度相同 */
  grid-auto-columns: 1fr; /* 确保每列宽度相同 */
  > div {
    margin: 10px;
    background-color: rgba(28, 31, 50, 80%);
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .threeD-main {
    grid-area: main;
    visibility: hidden;
  }
  .threeD-list {
    grid-area: list;
    margin-right: 20px;
  }
  .threeD-serve {
    grid-area: serve;
    margin-left: 20px;
  }
  .threeD-warm {
    grid-area: warm;
  }
  .threeD-day {
    grid-area: day;
  }
  .threeD-popule {
    grid-area: popule;
  }
  .threeD-car {
    grid-area: car;
    margin-right: 20px;
  }
}
</style>
