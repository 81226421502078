<script>
/**
 * @author lixidong
 * @description 告警列表
 */
import * as cameraApi from '@/api/camera_api'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
export default {
  data() {
    return {
      warningTable: {
        currentPage: 1,
        total: 0,
        pageSize: 10,
        value: [],
      },
      visible: false,
      visibleData: {},
    }
  },
  methods: {
    handler() {
      this.$bus.$emit('warm-show')
    },
    dialog(data) {
      this.visibleData = data
      this.visible = true
    },
    async getWarningList() {
      if (
        this.warningTable.currentPage >
          Math.ceil(this.warningTable.total / this.warningTable.pageSize) &&
        this.warningTable.currentPage != 1
      )
        return
      let res = await cameraApi.selectEvent({
        PageInfo: {
          PageIdx: this.warningTable.currentPage,
          PageSize: this.warningTable.pageSize,
        },
      })
      if (res.code === 200) {
        let { Total, WarningInfos } = res.data
        const { pageSize, value, currentPage } = this.warningTable
        WarningInfos = WarningInfos.map((item) => ({
          ...item,
          uuid: uuidv4(),
          Timestamp: moment(item.CreateTime).format('YYYY-MM-DD HH:mm:ss'),
        }))
        this.warningTable = {
          currentPage: currentPage + 1,
          total: Total,
          pageSize: pageSize,
          value: value.concat(WarningInfos),
        }
      }
    },
    // 时间转换
    dateChange(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>

<template>
  <div class="threeD-list">
    <div class="threeD-detail-header">
      <div>告警列表</div>
      <div>详情</div>
    </div>
    <div
      v-loading="warningTable.currentPage == 1 && warningTable.total == 0"
      element-loading-background="rgba(0,0,0,0.1)"
      ref="warning_box"
      class="threeD-detail-main"
    >
      <div
        style="cursor: pointer"
        v-for="item in warningTable.value"
        :key="item.uuid"
        @click="handler"
      >
        <el-image
          class="threeD-detail-main_image"
          :src="item.Snapshot"
          fit="fill"
        >
          <!-- <img
            style="width: 100%; height: auto"
            slot="error"
            :src="randomImage()"
            alt=""
          /> -->
        </el-image>
        <div class="threeD-list-info">
          <div style="color: var(--color-red)" class="ellipsis">抽烟告警</div>
          <el-row :gutter="10">
            <el-col style="display: flex" :span="24">
              <div style="flex-shrink: 0">时间：</div>
              <div
                style="color: var(--color-white); flex: auto; font-size: 14px"
              >
                {{ item.Timestamp }}
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="10">
            <el-col class="ellipsis" :span="24"
              >布点：<span style="color: var(--color-white)">{{
                item.CameraIP
              }}</span></el-col
            >
          </el-row>
        </div>
      </div>
      <div
        style="text-align: center; color: var(--color-white)"
        v-load-more="{ root: () => $refs.warning_box, loadfn: getWarningList }"
      ></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.threeD-list {
  .threeD-detail-main {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      margin-top: 20px;
      .threeD-detail-main_image {
        width: 120px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 80px;
        margin-right: 5px;
      }
      .threeD-list-info {
        flex: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80px;
      }
    }
    > div:first-child {
      margin-top: 0px;
    }
  }
}
</style>
