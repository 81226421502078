<script>
/**
 * @author lixidong
 * @description tab子组件
 */
import bus from './bus';
export default {
  props: {
    label: String / Number
  },
  data () {
    return {
      active: ''
    }
  },
  mounted () {
    bus.$on('update_active', val => this.active = val)
  },
}
</script>

<template>
  <div class="tabs_content-item" :class="{ 'tabs_content-item-active': label == active }">
    <slot />
  </div>
</template>

<style lang="less" scoped>
.tabs_content-item {
  visibility: hidden;
  width: 100%;
  flex-shrink: 0;
}

.tabs_content-item-active {
  visibility: visible;
}
</style>