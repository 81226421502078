<script>
/**
 * @auhtor lixidong
 * @description 用户管理
 */
import * as echarts from 'echarts'

export default {
  data() {
    return {
      chart: null,
      height: '140px',
    }
  },
  methods: {
    getLast30Days() {
      let result = []
      let date = new Date()
      for (let i = 0; i < 30; i++) {
        let day = new Date(date)
        day.setDate(date.getDate() - i)
        let month = (day.getMonth() + 1).toString().padStart(2, '0')
        let dayOfMonth = day.getDate().toString().padStart(2, '0')
        result.unshift(`${month}/${dayOfMonth}`)
      }
      return {
        xAxisData: result,
        yAxisData: Array.from({ length: 30 }, () =>
          Math.floor(Math.random() * 1000)
        ),
      }
    },

    main(width, height) {
      const { xAxisData, yAxisData: seriesData } = this.getLast30Days()

      var option = {
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
        },
        grid: {
          left: '14%', // Y 轴的空间
          right: '5%', // 右边距
          top: '10%', // 上边距
          bottom: '15%', // 下边距
        },
        yAxis: {
          type: 'value',
          min: 0, // 根据你的数据设置适当的最小值
          max: 1000, // 根据你的数据设置适当的最大值
        },
        series: [
          {
            name: '近30天报警',
            type: 'line',
            smooth: true, // 使线条平滑
            areaStyle: {}, // 开启面积图
            data: seriesData, // 使用随机生成的数据
            lineStyle: {
              width: 2, // 线条宽度
              color: '#3a52ff', // 线条颜色
            },
            itemStyle: {
              color: 'transparent', // 隐藏数据点
            },
            areaStyle: {
              color: 'rgba(58, 82, 255, 0.4)', // 背景色及透明度
              shadowColor: 'rgba(66, 184, 98, 0.8)', // 阴影颜色
              shadowBlur: 10, // 阴影模糊程度
              shadowOffsetX: 2, // 阴影水平偏移
              shadowOffsetY: 2, // 阴影垂直偏移
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider', // 选择 'slider' 类型来启用滑块缩放
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: 50, // 初始显示范围百分比
            handleSize: '8px', // 滑块的大小
            handleStyle: {
              color: '#1e90ff',
            },
            textStyle: {
              color: '#fff',
            },
          },
          {
            type: 'inside', // 选择 'inside' 类型来启用内置缩放
            xAxisIndex: [0],
          },
        ],
      }
      var chart = echarts.init(this.$refs.box, null, { width, height })
      chart.setOption(option)
      this.chart = chart
    },
    resize(domData) {
      const { width, height } = domData[0].contentRect
      if (this.chart) {
        this.chart.resize({ width, height })
      } else {
        this.main(width, height)
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<template>
  <div class="threeD-day">
    <div class="threeD-detail-header">
      <div>近30天报警</div>
      <div>详情</div>
    </div>
    <div v-resize="resize" ref="box" class="threeD-detail-main"></div>
  </div>
</template>
