/**
 * @author lixidong
 * @description jquery 请求封装
 */
import $ from 'jquery'

import { Message } from 'element-ui'
import { SERVERURLS } from '@/config'
import { serverUrlHelper } from '@/utils/tool.js'
const TIMEOUT = 6000
console.log('当前模式', process.env.NODE_ENV)
// 用于设置 jQuery Ajax 请求的全局配置，将请求的缓存设置为 false。
$.ajaxSetup({ cache: false })

/**
 * @description breforeSend
 * @param {String} url
 * @returns {Object}
 */
const breforeSend = (url) => {
  let serverUrl = serverUrlHelper(url, SERVERURLS)
  if (serverUrl && serverUrl.transitionBreforeSend)
    return function () {
      return serverUrl.transitionBreforeSend.apply(this.arguments)
    }
  return function (xhr) {
    const token = window.localStorage.getItem('IDENTITYSERVER_TOKEN')
    token && xhr.setRequestHeader('Authorization', 'Bearer ' + token)
  }
}

/**
 * @description success
 * @param {*} url
 * @returns {code , data}
 */
const success = (url, resolve) => {
  return function (response) {
    if (process.env.VUE_APP_MOCK && typeof response === 'string') {
      response = JSON.parse(response)
    }
    let serverUrl = serverUrlHelper(url, SERVERURLS)
    if (serverUrl) {
      const _response = serverUrl.transitionResponse(response)
      if (_response.code === 400) {
        Message({ showClose: true, type: 'error', message: _response.message })
      }
      return resolve(_response)
    }
    Message({
      showClose: true,
      type: 'error',
      message: `url "${url}" does not exist in SERVERURLS .`,
    })
  }
}

/**
 * @description error
 * @param {*} url
 * @returns
 */
const error = (url, reject) => {
  return function (xhr, status, error) {
    let serverUrl = serverUrlHelper(url, SERVERURLS)
    if (serverUrl && serverUrl.transitionError) {
      const options = serverUrl.transitionError.apply(this, arguments)
      options && Message(Object.assign({ showClose: true }, options))
    }
    reject(error)
  }
}

// 封装 $.get() 方法，返回一个 Promise 对象
export const get = (url, data = {}, params = {}) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'GET',
      data: data,
      timeout: params.timeout || TIMEOUT,
      headers: params.headers || {},
      xhrFields: {
        withCredentials: params.withCredentials || false,
        responseType: params.responseType,
      },
      beforeSend: breforeSend(url),
      success: success(url, resolve),
      error: error(url, reject),
    })
  })
}

// 封装 $.post() 方法，返回一个 Promise 对象
export const post = (url, data = {}, params = {}) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'POST',
      data: data,
      timeout: params.timeout || TIMEOUT,
      headers: params.headers || {},
      contentType: params.contentType || 'application/x-www-form-urlencoded',
      xhrFields: {
        withCredentials: params.withCredentials || false,
        responseType: params.responseType,
      },
      beforeSend: breforeSend(url),
      success: success(url, resolve),
      error: error(url, reject),
    })
  })
}

//封装 $.delete() 方法，返回一个 Promise 对象
export const del = (url, data = {}, params = {}) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'DELETE',
      data: data,
      timeout: params.timeout || TIMEOUT,
      headers: params.headers || {},
      contentType: params.contentType || 'application/x-www-form-urlencoded',
      xhrFields: {
        withCredentials: params.withCredentials || false,
        responseType: params.responseType,
      },
      beforeSend: breforeSend(url),
      success: success(url, resolve),
      error: error(url, reject),
    })
  })
}

//封装 $.put() 方法，返回一个 Promise 对象
export const put = (url, data = {}, params = {}) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url: url,
      type: 'PUT',
      data: data,
      timeout: params.timeout || TIMEOUT,
      headers: params.headers || {},
      contentType: params.contentType || 'application/x-www-form-urlencoded',
      xhrFields: {
        withCredentials: params.withCredentials || false,
        responseType: params.responseType,
      },
      beforeSend: breforeSend(url),
      success: success(url, resolve),
      error: error(url, reject),
    })
  })
}

// 上传文件
export const upload = (url, data) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url,
      type: 'POST',
      data,
      processData: false,
      contentType: false,
      success: success(url, resolve),
      error: error(url, reject),
    })
  })
}

// 下载文件
export const download = (url) => {
  return new Promise(function (resolve, reject) {
    $.ajax({
      url,
      type: 'GET',
      xhrFields: {
        responseType: 'blob',
      },
      success: (data) => resolve(data),
      error: error(url, reject),
    })
  })
}
