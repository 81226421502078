import { Message } from "element-ui";
const inputUpload= {
    bind(el, binding) {
        const changefunc = binding.value;
        function fileFn(event){
            const limit = event.target.getAttribute('limit')*1 || 1;
            const files=event.target.files;
            const errorText=event.target.getAttribute('error-text')
            if(files.length > limit){
                return Message.error(errorText || "文件数量超出限制，请上指定个数的文件。")
            }
            Array.from(files).forEach(file => changefunc(file));
        }
        el.addEventListener('change',fileFn)
        el._change = fileFn
    },
    unbind(el) {
        el._change && el.removeEventListener('change', el._change)
    }
}

export default inputUpload