<script>
/**
 * @author lixidong
 * @description 边缘配置新增盒子
 */
import CarmeraDialog from './carmeraDialog.vue'
import * as cameraApi from '@/api/camera_api'
import { v4 as uuidv4 } from 'uuid'
import gsap from 'gsap'
import Vue from 'vue'
export default {
  components: { CarmeraDialog },
  provide() {
    return {
      state: this.state,
    }
  },
  data() {
    return {
      state: Vue.observable({
        type: '',
      }),
      status: false,
      // 分页
      treeValue: {
        pageSize: 200,
        currentPage: 1,
        total: 0,
        filters: null,
        treeData: [],
      },
      dialogVisible: false,
      // type: '',
      replace: false,
      // 选中的box
      selectBox: null,
      selectLock: true,
      // 弹窗选中
      selectDialogBox: null,
    }
  },
  computed: {
    type: {
      get() {
        return this.state.type
      },
      set(value) {
        return (this.state.type = value)
      },
    },
    activeIndex() {
      if (this.selectBox) {
        return this.treeValue.treeData.findIndex(
          (item) => item.Id == this.selectBox.Id
        )
      }
      return null
    },
  },
  methods: {
    // 关闭弹窗
    cancelDialog() {
      this.dialogVisible = false
      this.selectBox = null
      this.resetList()
    },
    // 重置列表
    async resetList() {
      this.treeValue = this.$options.data().treeValue
      await this.boxLoad()
    },
    // 加载更多
    async boxLoad() {
      const query = this.treeValue
      if (
        query.currentPage > 1 &&
        query.currentPage > Math.ceil(query.total / query.pageSize)
      )
        return
      let res = await cameraApi.selectBoxs({
        PageInfo: {
          PageIdx: query.currentPage,
          PageSize: query.pageSize,
        },
      })
      if (res.code == 200) {
        const { AIBoxes, Total } = res.data
        const treeData = AIBoxes.map((item) => ({ uuid: uuidv4(), ...item }))
        this.treeValue = {
          ...query,
          total: Total,
          currentPage: query.currentPage + 1,
          treeData: [...query.treeData, ...treeData],
        }
        if (query.currentPage == 1 && treeData.length > 0) {
          this.selectBoxFn(treeData[0])
        }
      }
    },
    // 删除盒子
    delBox(row) {
      this.$confirm('确认删除该边缘盒子吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { Id } = row
          let res = await cameraApi.deleteBox({ Id })
          if (res.code != 200) return
          this.selectBox = null
          // 刷新表格数据
          this.resetList()
        })
        .catch(() => {})
    },
    selectBoxFn(item) {
      this.selectBox = item
      this.$emit('getBoxInfo', item)
      // 背景动画
      if (this.$refs.warningbg) {
        this.selectLock = false
        gsap.to(this.$refs.warningbg, {
          top: `${this.activeIndex * 36 + 8}px`,
          duration: 0.3,
          ease: 'power2.inOut',
          onComplete: () => {
            this.selectLock = true
          },
        })
      }
    },
    // 新增修改盒子
    addBox(type, item) {
      this.dialogVisible = true
      this.status = false
      this.type = type
      if (type == 'edit') {
        this.selectDialogBox = item
      }
      if (type == 'add') this.selectDialogBox = null
      this.replace = true
    },
  },
  watch: {
    dialogVisible: {
      handler(newVal) {
        if (!newVal) {
          this.replace = false
          this.selectBox && this.$emit('getChannlInfo', this.selectBox)
        }
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<template>
  <div ref="warning_box" class="warning-box">
    <div class="warning-box_sticky is-top"></div>
    <div class="warning-box_ul">
      <li
        @click="selectBoxFn(item)"
        v-for="item in treeValue.treeData"
        :key="item.uuid"
        class="warning-box_li"
      >
        <div class="ellipsis arning-box_template-label">{{ item.Name }}</div>
        <div class="warning-box_template-controls">
          <auth-wrapper slot auth="update-edge">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                @click="addBox('edit', item)"
                icon="el-icon-setting"
                :underline="false"
              ></el-link>
            </template>
          </auth-wrapper>
          <auth-wrapper slot auth="delete-edge">
            <template v-slot="{ root }">
              <el-link
                :disabled="!root"
                @click.stop="delBox(item)"
                icon="el-icon-delete"
                :underline="false"
              ></el-link>
            </template>
          </auth-wrapper>
        </div>
      </li>
    </div>
    <div v-if="activeIndex !== null" ref="warningbg" class="warning-box_bg">
      <div v-if="selectLock" class="ellipsis arning-box_template-label">
        {{ selectBox.Name }}
      </div>
      <div v-if="selectLock" class="warning-box_template-controls">
        <auth-wrapper slot auth="update-edge">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              @click.stop="addBox('edit', selectBox)"
              icon="el-icon-setting"
              :underline="false"
            ></el-link>
          </template>
        </auth-wrapper>
        <auth-wrapper slot auth="delete-edge">
          <template v-slot="{ root }">
            <el-link
              :disabled="!root"
              @click.stop="delBox(selectBox)"
              icon="el-icon-delete"
              :underline="false"
            ></el-link>
          </template>
        </auth-wrapper>
      </div>
    </div>
    <div
      class="warning-box_load-more"
      v-load-more="{
        root: () => $refs.warning_box,
        loadfn: boxLoad,
        thresholdpx: '38px',
      }"
    ></div>
    <div class="warning-box_sticky">
      <auth-wrapper slot auth="add-edge">
        <template v-slot="{ root }">
          <el-button
            :disabled="!root"
            @click="addBox('add')"
            size="mini"
            icon="el-icon-circle-plus-outline"
            >新增盒子</el-button
          >
        </template>
      </auth-wrapper>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog
      append-to-body
      width="80vw"
      top="100px"
      title="智能盒子配置"
      :visible.sync="dialogVisible"
    >
      <carmera-dialog
        v-if="replace"
        :selectDialogBox="selectDialogBox"
        :status.sync="status"
        ref="carmera"
      />
      <div slot="footer">
        <el-button
          size="small"
          :disabled="(type === 'add' ? false : true) && !status"
          @click="() => $refs.carmera.addChannel && $refs.carmera.addChannel()"
          style="float: left"
          type="success"
          >新增通道</el-button
        >
        <el-button
          v-if="type === 'add'"
          :loading="(type === 'add' ? false : true) && !status"
          size="small"
          :disabled="(type === 'add' ? false : true) && !status"
          @click="
            () =>
              $refs.carmera.submit && $refs.carmera.submit(() => cancelDialog())
          "
          type="primary"
          >启动</el-button
        >
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.warning-box {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: relative;

  .warning-box_ul {
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;

    .warning-box_li {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      overflow: hidden;
      padding: 0 8px;
      width: 100%;
      line-height: 36px;
      box-sizing: border-box;

      .arning-box_template-label {
        flex: auto;
      }

      .warning-box_template-controls {
        width: 40px;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .warning-box_sticky {
    position: sticky;
    bottom: 0px;
    text-align: center;
    padding: 8px;
  }

  .warning-box_sticky.is-top {
    bottom: auto;
    top: 0px;
    padding: 0;
    height: 8px;
    z-index: 100;
  }

  .warning-box_load-more {
    text-align: center;
  }

  .warning-box_bg {
    position: absolute;
    top: 8px;
    left: 10px;
    height: 30px;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    padding: 0 8px;
    width: calc(100% - 20px);
    border-radius: 5px;
    line-height: 36px;
    box-sizing: border-box;

    .arning-box_template-label {
      flex: auto;
    }

    .warning-box_template-controls {
      width: 40px;
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
