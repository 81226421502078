var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"selector-item",class:{ active: _vm.auth },attrs:{"data-index":'通道' + (_vm.index + 1)}},[_c('el-form',{attrs:{"size":"small","label-width":"80px"}},[_c('el-row',{staticClass:"selector-item_row",attrs:{"gutter":5}},[_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"相机"}},[_c('el-select',{on:{"change":_vm.cameraSelectionChanged},model:{value:(_vm.selectedCamera),callback:function ($$v) {_vm.selectedCamera=$$v},expression:"selectedCamera"}},_vm._l((_vm.cameras),function(camera){return _c('el-option',{key:camera.Id,attrs:{"value":camera.Id,"label":camera.Name}})}),1)],1)],1),(_vm.cameraIsOnvif)?_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"视频流"}},[_c('el-select',{model:{value:(_vm.selectedProfile),callback:function ($$v) {_vm.selectedProfile=$$v},expression:"selectedProfile"}},_vm._l((_vm.profiles),function(profile,index){return _c('el-option',{key:index,attrs:{"value":profile.Token,"label":profile.Token +
                ' （' +
                profile.VideoEncoderConfiguration.Resolution.Width +
                'x' +
                profile.VideoEncoderConfiguration.Resolution.Height +
                ' ' +
                profile.VideoEncoderConfiguration.Encoding +
                ' @ ' +
                profile.VideoEncoderConfiguration.RateControl.FrameRateLimit +
                'fps）'}})}),1)],1)],1):_vm._e(),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"算法"}},[_c('el-select',{model:{value:(_vm.selectedPipeline),callback:function ($$v) {_vm.selectedPipeline=$$v},expression:"selectedPipeline"}},_vm._l((_vm.pipelines),function(pipeline,index){return _c('el-option',{key:index,attrs:{"value":pipeline.Id,"label":pipeline.Name + ' - ' + pipeline.Version}})}),1)],1)],1),_c('el-col',{staticStyle:{"display":"flex"},attrs:{"span":8}},[(_vm.other.state)?_c('el-tag',{staticStyle:{"margin":"0 5px","padding":"9px 15px","line-height":"1","height":"auto"},attrs:{"size":"small","type":"info"}},[_vm._v(_vm._s(_vm.other.state))]):_vm._e(),(_vm.type == 'edit' && _vm.auth)?_c('el-button',{attrs:{"size":"small","type":"success","disabled":_vm.disabled},on:{"click":_vm.updatePipeline}},[_vm._v("更新")]):_vm._e(),(_vm.type == 'edit' && !_vm.auth)?_c('el-button',{attrs:{"size":"small","type":"success","disabled":_vm.disabled},on:{"click":_vm.startPipeline}},[_vm._v("启动")]):_vm._e(),_c('el-button',{attrs:{"size":"small","type":"danger","disabled":_vm.auth && _vm.disabled},on:{"click":_vm.deletePipeline}},[_vm._v("删除")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }