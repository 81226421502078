var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tree-sider"},[_c('div',{staticClass:"nsr-search"},[_c('el-input',{attrs:{"size":"mini","placeholder":"请输入设备IP或设备名称","suffix-icon":"el-icon-search"},on:{"change":_vm.handleChannelSearch},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('div',{ref:"nsr_tree",staticClass:"nsr-tree"},[_c('el-tree',{staticClass:"nsr-tree-content",attrs:{"data":_vm.treeValue.treeData,"props":{
        children: 'children',
        label: 'name',
        isLeaf: 'leaf',
      },"load":_vm.loadNode,"lazy":""},on:{"node-click":_vm.handleNodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return _c('div',{staticClass:"nsr-tree-span"},[_c('div',{staticClass:"nsr-tree_label"},[(node.level === 1)?_c('monitor',{attrs:{"theme":"outline","size":"14","fill":"#fff","strokeLinecap":"butt"}}):_c('camera-five',{attrs:{"theme":"outline","size":"14","fill":_vm.$ifspecification(data.status, 'on') ? '#67c23a' : '#fff',"strokeLinecap":"butt"}}),_c('span',{staticStyle:{"font-size":"14px","margin-left":"2px"}},[_vm._v(_vm._s(node.label || '未命名'))])],1)])}}])}),(
        _vm.treeValue.total > 0 &&
        _vm.treeValue.treeData.length < _vm.treeValue.total &&
        _vm.loadable
      )?_c('div',{directives:[{name:"load-more",rawName:"v-load-more",value:({
        root: () => _vm.$refs.nsr_tree,
        loadfn: _vm.device_setting_list,
      }),expression:"{\n        root: () => $refs.nsr_tree,\n        loadfn: device_setting_list,\n      }"}],staticClass:"nsr_load-more",on:{"click":_vm.device_setting_list}},[_vm._v(" 加载更多 ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }